import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { WWCharacter } from '../common/components/ww-character';

interface IProps {
  name: string;
}

export const WWEchoStatsComparison: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'xiangli-yao' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Abyss Surges S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,207 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.09%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,947 DPS</span>
                          <span className="percent">96.09%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '90.41%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">29,117 DPS</span>
                          <span className="percent">90.41%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '89.16%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">28,716 DPS</span>
                          <span className="percent">89.16%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>Echo setup comparison (Verity's Handle S1)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,250 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.75%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,155 DPS</span>
                          <span className="percent">99.75%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.82%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">37,033 DPS</span>
                          <span className="percent">96.82%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Xiangli Yao 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.51%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,914 DPS</span>
                          <span className="percent">96.51%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'zhezhi' && (
        <>
          <h6>Comparison of 4-3-3-1-1 (Cosmic Ripples S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">26,845 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '93.38%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">25,067 DPS</span>
                          <span className="percent">93.38%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h6>Comparison of 4-3-3-1-1 (Rime-Draped Sprouts S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,254 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Zhezhi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.31%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,975 DPS</span>
                          <span className="percent">96.31%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'changli' && (
        <>
          <h6>Comparison of 4-3-3-1-1 (Emerald of Genesis S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">39,079 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '97.87%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,248 DPS</span>
                          <span className="percent">97.87%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '92.53%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,160 DPS</span>
                          <span className="percent">92.53%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h6>Comparison of 4-3-3-1-1 (Blazing Brilliance S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">43,012 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.61%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">42,844 DPS</span>
                          <span className="percent">99.61%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Changli 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.30%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">41,423 DPS</span>
                          <span className="percent">96.30%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1 (Lustrous Razor S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">43,538 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '98.15%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">42,732 DPS</span>
                          <span className="percent">98.15%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '93.37%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">40,652 DPS</span>
                          <span className="percent">93.37%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '92.52%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">40,283 DPS</span>
                          <span className="percent">92.52%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1 (Ages of Harvest S1)</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">51,431 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ATK% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.61%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">51,233 DPS</span>
                          <span className="percent">99.61%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '97.44%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">50,116 DPS</span>
                          <span className="percent">97.44%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jinhsi 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '94.58%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">48,645 DPS</span>
                          <span className="percent">94.58%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'calcharo' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Calcharo 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,671 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Calcharo 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.2%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,430 DPS</span>
                          <span className="percent">96.2%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Calcharo 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '87.51%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">28,592 DPS</span>
                          <span className="percent">87.51%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'yuanwu' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yuanwu 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">40,296 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yuanwu 4-3-3-1-1 (ELE% + DEF%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '96.05%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,706 DPS</span>
                          <span className="percent">96.05%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yuanwu 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '89.88%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,218 DPS</span>
                          <span className="percent">89.88%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'danjin' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Danjin 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,872 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Danjin 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '99.23%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">35,595 DPS</span>
                          <span className="percent">99.23%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Danjin 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '97.47%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">34,966 DPS</span>
                          <span className="percent">97.47%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'yinlin' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yinlin 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">58,894 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yinlin 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-2`}
                          style={{ width: '94.7%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">55,775 DPS</span>
                          <span className="percent">94.7%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Yinlin 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '87.32%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">51,425 DPS</span>
                          <span className="percent">87.32%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <h6>Comparison of 4-3-3-1-1 and 4-4-1-1-1</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Rover 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">42,333 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Rover 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '98.43%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">41,670 DPS</span>
                          <span className="percent">98.43%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Rover 4-4-1-1-1</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '92%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,946 DPS</span>
                          <span className="percent">92%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'chixia' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Chixia 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,432 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Chixia 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '98.12%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">30,840 DPS</span>
                          <span className="percent">98.12%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'jiyan' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jiyan 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">33,503 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jiyan 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '95.99%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,161 DPS</span>
                          <span className="percent">95.99%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'lingyang' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lingyang 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">29,513 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lingyang 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '97.31%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">28,718 DPS</span>
                          <span className="percent">97.31%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'mortefi' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Mortefi 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">38,199 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Mortefi 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '96.6%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">36,899 DPS</span>
                          <span className="percent">96.6%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'rover-spectro' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Rover 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">32,084 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Rover 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '99.07%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,787 DPS</span>
                          <span className="percent">99.07%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'sanhua' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Sanhua 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">19,866 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Sanhua 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '94.93%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">18,858 DPS</span>
                          <span className="percent">94.93%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'aalto' && (
        <>
          <h6>Comparison of 4-3-3-1-1 variants</h6>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>Echo setup comparison</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Aalto 4-3-3-1-1 (ELE% + ELE%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">31,110 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <WWCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Aalto 4-3-3-1-1 (ELE% + ATK%)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-3`}
                          style={{ width: '95.65%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">29,757 DPS</span>
                          <span className="percent">95.65%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
