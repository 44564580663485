import React, { useState } from 'react';
import { WWCharacter } from '../common/components/ww-character';
import { WWCharactersDMG } from '../data/ww-char-damage';
import { Alert, Row, Col, Accordion } from 'react-bootstrap';
import { WWWeaponEntry } from '../weapons/ww-weapon';
import { WWSet } from '../common/components/ww-set';
import { WWStat } from '../common/components/ww-stat';
import { WWEchoEntry } from '../echoes/ww-echo';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YoutubeEmbed } from '../../common/components/youtube-embed';

interface IProps {
  slug: string;
  name: string;
  element: string;
  onlyRotation?: boolean;
}

export const WWCharacterCalcs: React.FC<IProps> = ({
  slug,
  name,
  element,
  onlyRotation
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dpsRanking, setDpsRanking] = useState(
    WWCharactersDMG.find((rankingCharacter) => {
      if (rankingCharacter.slug === slug) return true;
    })
  );

  return (
    <>
      {!dpsRanking ? (
        <>
          <div className="info-box">
            {onlyRotation ? (
              <>
                {slug === 'baizhi' && (
                  <>
                    <h6>ROTATION INFO</h6>
                    <p>
                      When playing Baizhi focus on achieving the following in
                      combat:
                    </p>
                    <ul>
                      <li>
                        Whenever her Resonance Skill is recharged or close to
                        it, ensure to fill her Forte Gauge as soon as possible
                        before executing the following:
                      </li>
                      <ul>
                        <li>Basic P1</li>
                        <li>Basic P2</li>
                        <li>Basic P3</li>
                        <li>Basic P4</li>
                        <li>Resonance Skill (Forte Empowered)</li>
                      </ul>
                      <li>
                        Always utilize Baizhi’s Ultimate as frequently as
                        possible aside from saving it for healing purposes.
                      </li>
                      <li>
                        Use her Echo as frequently as possible asides from
                        saving it for usage just before a burst combo.
                      </li>
                      <li>
                        After triggering her Outro, ensure to switch to main DPS
                        to maintain the 15% damage amplification.
                      </li>
                    </ul>
                  </>
                )}
                {slug === 'verina' && (
                  <>
                    <h6>ROTATION INFO</h6>
                    <p>
                      When playing Verina focus on achieving the following in
                      combat:
                    </p>
                    <ul>
                      <li>
                        Keep your Resonance Skill on cooldown at all times,
                        unless its usage interrupts use of other higher priority
                        cooldowns or DPS burst windows.
                      </li>
                      <li>
                        Always utilize Verina’s Ultimate as frequently as
                        possible aside from saving it for healing purposes.
                      </li>
                      <li>
                        Use her Echo as frequently as possible asides from
                        saving it for usage just before a burst combo.
                      </li>
                      <li>
                        Use her Forte Gauge charges to quickly generate Concerto
                        Energy, Heavy attacks generate the most energy, however
                        you can use Aerial Attacks instead to quickly use all
                        charges instead.
                      </li>
                      <li>
                        Ensure you trigger a heal from Verina, every 20 seconds
                        to keep her Attack% boost active for the combat
                        duration, moreso just before main damage combos or
                        cooldown expiry. This can be achieved via spending her
                        Forte Gauge charge, using her Outro or using her
                        Ultimate.
                      </li>
                    </ul>
                  </>
                )}
                {slug === 'yangyang' && (
                  <>
                    <h6>ROTATION INFO</h6>
                    <p>
                      When playing Yangyang as a support focus on achieving the
                      following in combat:
                    </p>
                    <ul>
                      <li>
                        Keep your Resonance Skill on cooldown at all times,
                        unless its usage interrupts use of other higher priority
                        cooldowns or DPS burst windows.
                      </li>
                      <li>
                        Always utilize Yangyang’s Ultimate as frequently as
                        possible aside from saving it to Group-Up enemies at
                        specific points of combat.
                      </li>
                      <li>
                        Use her Echo as frequently as possible asides from
                        saving it for usage just before a burst combo when using
                        Bell-Borne Echo, or alongside her Outro to buff one of
                        your core DPS when using Impermanence Heron,
                      </li>
                      <li>
                        Utilize her Forte Gauge charges whenever filled to jump
                        Aerial Plunge (not Heavy Aerial Plunge) to generate
                        massive amounts of Concerto Energy. This can be
                        Swap-Canceled to protect her during its long animation
                        while saving field time.
                      </li>
                      <li>
                        Incase of spare field time or need to use her Concerto
                        Gauge in a pinch, Yangyang can build additional Forte
                        Gauge charges via Heavy Attacks or a full Basic Attack
                        sequence to accelerate the process.
                      </li>
                    </ul>
                  </>
                )}
                {slug === 'taoqi' && (
                  <>
                    <h6>ROTATION INFO</h6>
                    <p>
                      When playing Taoqi as a support focus on achieving the
                      following in combat:
                    </p>
                    <ul>
                      <li>
                        Keep your Resonance Skill on cooldown at all times,
                        unless its usage interrupts use of other higher priority
                        cooldowns or DPS burst windows.
                      </li>
                      <li>
                        Always utilize Taoqi’s Ultimate as frequently as
                        possible aside from saving it to trigger her Concerto at
                        specific points to take advantage of her Outro
                        amplification.
                      </li>
                      <li>
                        Use her Echo as frequently as possible asides from
                        saving it for usage just before a burst combo when using
                        Bell-Borne Echo, or alongside her Outro to buff one of
                        your core DPS when using Impermanence Heron,
                      </li>
                      <li>
                        You can optionally generate a large amount of Concerto
                        and Resonance Energy by utilizing her Intro followed by
                        Forte Gauge Charges to execute the empowered Basic
                        Attack sequence.
                      </li>
                    </ul>
                  </>
                )}
                {slug != 'baizhi' &&
                  slug != 'verina' &&
                  slug != 'taoqi' &&
                  slug != 'yangyang' && (
                    <p>
                      <strong className={`${element}`}>{name}</strong>{' '}
                      <strong>
                        rotation information aren't available yet.
                      </strong>
                    </p>
                  )}
              </>
            ) : (
              <>
                <p>
                  <strong className={`${element}`}>{name}</strong>{' '}
                  <strong>calculations aren't available yet.</strong>
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {onlyRotation ? (
            <div className="optimal-rotation">
              <p>
                This is the optimal rotation for the character. The total time
                needed to perform it is{' '}
                <strong className={element}>{dpsRanking.time} seconds</strong>.
                When selecting the rotations we prioritized achieving 100
                Concerto as fast as possible and once that condition was
                fulfilled, we focused on maximizing the damage output. As or if
                new and more optimal rotations are found, we'll update the
                characters calculations as needed.
              </p>
              <hr />
              {slug === 'the-shorekeeper' && (
                <>
                  <p>
                    When playing Shorekeeper there are 2 rotations to make note
                    of: her opener which must be performed without access to her
                    intro skill and her standard rotation which can make use of
                    it.
                  </p>
                  <p>
                    When starting a fight in endgame modes such as the Tower of
                    Adversity none of your team members will have access to any
                    concerto. In order for Shorekeeper's ultimate to gain its
                    full effect it's imperative your team can quickly cycle
                    through at least 2 Intro skills after its use. Because of
                    these two factors it makes the most sense to build
                    Shorekeeper's Concerto as fast as possible, then use her
                    ultimate and quickly execute her Outro followed by another
                    team members Intro in these game modes. Here is an efficient
                    rotation in order to achieve this (depending on your team
                    altering this intro to incorporate quick swapping may
                    improve performance).
                  </p>
                  <blockquote>
                    <h6>Opener Rotation:</h6>
                    <ul>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>Forte: Heavy Atk: Illation</li>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>Forte: Heavy Atk: Illation</li>
                      <li>Skill: Chaos Theory</li>
                      <li>Echo</li>
                      <li>Liberation</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <p>
                    After the opening rotation is complete and you have access
                    to concerto across your team to ensure Shorekeeper can gain
                    an intro execute the following rotation instead for greater
                    efficiency;
                  </p>
                  <blockquote>
                    <h6>Standard Rotation:</h6>
                    <ul>
                      <li>Intro Skill: Discernment</li>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>Forte: Heavy Atk: Illation</li>
                      <li>Skill: Chaos Theory</li>
                      <li>Echo</li>
                      <li>Liberation</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <p>
                    Both of these rotations require between 6-8 additional
                    concerto energy in order to function. This is easily
                    acquired by equipping either a Variation S1 or Shorekeeper's
                    signature. If you have neither of these you'll need to
                    supplement the missing Concerto energy by including
                    additional Basic Attacks or a Dodge Counter.
                  </p>
                </>
              )}
              {slug === 'xiangli-yao' && (
                <>
                  <p>
                    <strong>Warm Up</strong>
                  </p>
                  <p>
                    Xiangli Yao requires usage of at least 1 Standard Resonance
                    Skill before his burst combo in order to have enough
                    Concerto Energy to cast his Outro. Fortunately, his
                    Resonance Skill is incredibly fast to use, can be
                    Swap-Canceled and generates good Resonance Energy. The Skill
                    also features an incredibly short cooldown of 4 seconds,
                    allowing you to weave it in between other characters actions
                    to accumulate Ultimate's even faster. If Xiangli Yao's
                    Ultimate is still on cooldown and you have field time or a
                    Swap available doing so is recommended.
                  </p>
                  <blockquote>
                    <ul>
                      <li>
                        Skill: Deduction (Swap Cancel)(Use atleast 1 per
                        ultimate combo)
                      </li>
                    </ul>
                  </blockquote>
                  <p>
                    <strong>BURST COMBO</strong>
                  </p>
                  <p>
                    Majority of Xiangli Yao’s field time and team's attention
                    should be centered around his Ultimate burst window, as it
                    inflicts majority of his as well as team's damage. Ensure to
                    line up all team’s kit, Echo Buffs/Amplifications, Xiangli
                    Yao’s Intro and cast at least 1 Deduction before initiating
                    his burst combo
                  </p>
                  <p>
                    Below given is his most optimal combo, without the need of
                    Swap mid-Ultimate. While it may look, confusing or complex
                    at first, here are the basics:
                  </p>
                  <ul>
                    <li>
                      Using Xiangli’s Yao Ultimate puts him in Intuition state
                      for 24 seconds, during this all of his abilities are
                      enhanced and ends upon execution of Forte Skill: Law of
                      Reigns 3 times.
                    </li>
                    <li>
                      After Ultimate activation, his most potent ability is
                      Forte Skill: Law of Reigns, which requires 5 Performance
                      Capacity to use. Thus making your new to build Performance
                      Capacity as soon as possible, execute 3 Law of Reigns and
                      work on the next Ultimate.
                    </li>
                    <li>
                      Fastest way to build 5 Performance Capacity is to use
                      Ultimate Skill: Divergence, followed by Forte Mid Air
                      Attack: Revamp, but due to it having a cooldown of 7
                      seconds, it can’t be repeated 3 times for all Law of
                      Reigns Triggers.
                    </li>
                    <li>
                      The next best way of generating Performance Capacity is
                      casting 3 Ultimate Basic: Pivot Impales.
                    </li>
                    <li>
                      Combining all this is Xiangli Yao’s combo in a nutshell.
                      You initiate with Divergence + Revamp, follow it up with
                      Law of Reigns, then use 3 Pivot Imaples, followed up with
                      another Law of Reigns before repeating Divergence + Revamp
                      and executing the final Law of Reigns.
                    </li>
                    <li>
                      This combo might need alternations in certain combat
                      scenarios should you feel the need to incorporate Dodge
                      Counters.
                    </li>
                  </ul>
                  <blockquote>
                    <h6>Combo</h6>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate: Cogiation Model</li>
                      <li>Ultimate: Skill: Divergence</li>
                      <li>Forte: Mid-Air Attack: Revamp</li>
                      <li>
                        Forte: Skill: Law of Reigns (Optionally Swap-Cancel)
                      </li>
                      <li>Ultimate: Basic: Pivot - Impale P1</li>
                      <li>Ultimate: Basic: Pivot - Impale P2</li>
                      <li>Ultimate: Basic: Pivot - Impale P3</li>
                      <li>
                        Forte: Skill: Law of Reigns (Optionally Swap-Cancel)
                      </li>
                      <li>Ultimate: Skill: Divergence</li>
                      <li>Forte: Mid-Air Attack: Revamp </li>
                      <li>Forte: Skill: Law of Reigns (Swap-Cancel)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <p>
                    While many of Xiangli Yao's abilities within his burst combo
                    are Swap-Cancellable and benefit heavily from doing the
                    same, it comes at a cost, as a lot of the most powerful team
                    buffs (Outro Amplifications) only apply to the entering
                    character while they remain on field. This means you must
                    make a choice between Swap-Canceling and Amplification Outro
                    buffs, the best choice being dependent on your choice of
                    team mate. Yinlin for example offers Xiangli Yao 20% Electro
                    Amplification and 25% Liberation Amplification for not
                    Swapping out, something very worth for not considering
                    Swap-Cancel for. Changli on the other hand offers far less
                    Outro Amplification options and benefits far more from
                    Swap-Cancels to power up her damage dealing rotation.
                    Xiangli Yao is capable and does well using both playstyles,
                    thus we recommend trying out both playstyles with the
                    characters on your account to find what hits the spot for
                    you.
                  </p>
                </>
              )}
              {slug === 'zhezhi' && (
                <>
                  <p>
                    <strong>Zhezhi Standard Combo</strong>
                  </p>
                  <p>
                    Zhezhi features an incredibly simple and easy to execute
                    combo, but does require having Ultimate cooldown available,
                    sufficient energy generation from other teammates and an
                    Intro skill ready for her use. If everything is ready,
                    execute the following:
                  </p>
                  <ul>
                    <li>
                      Execute her Intro skill, followed by Basic Attack 1, 2 and
                      3 to generate 90 Afflatus (Forte Gauge with 3 segments),
                      as long as all these hit, you will get all 90.
                    </li>
                    <li>
                      Tap/Hold her Resonance Skill to convert 60 Afflatus into 2
                      Imprints, tap if you want to execute faster ground based
                      combo, hold if you want to execute airborne combo instead.
                    </li>
                    <li>
                      Quickly follow up Resonance Skill with a single Basic
                      Attack (tap) to convert the last 30 Afflatus into 3rd
                      Imprint.
                    </li>
                    <li>
                      Execute 3 back to back Resonance Skills to consume all 3
                      Imprints and leave a short pause for final attack to
                      complete the majority of its animation.
                    </li>
                    <li>
                      Activate her Ultimate followed by her Echo, immediately
                      Swap-Canceling the same.(Some adjustment might be required
                      if not using Impermanence Heron)
                    </li>
                  </ul>
                  <blockquote>
                    <h6>Combo</h6>
                    <ul>
                      <li>Intro Skill</li>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>
                        Resonance Skill: Manifestation: (Tap to Execute Ground
                        combo, Hold To Execute Air Combo)
                      </li>
                      <li>Forte: Heavy Atk: Conjuration</li>
                      <li>Forte: Resonance Skill: Stroke of Genius</li>
                      <li>Forte: Resonance Skill: Stroke of Genius</li>
                      <li>Forte: Resonance Skill: Creations Zenith</li>
                      <li>Ultimate: Living Canvas</li>
                      <li>
                        Echo: Impermanence Heron (Swap-Cancel immediately after
                        activation)
                      </li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'changli' && (
                <>
                  <p>
                    <strong>CHANGLI ABILITY PRIORITY</strong>
                  </p>
                  <p>
                    Changli is most effective when played as a Quick-Swap
                    character, executing cooldown-based combos as they become
                    available. Depending on the teammates you pair her with, how
                    much field time they utilize and the fight itself, cooldowns
                    may become available at different timings. When playing
                    Changli aim to follow the Ability Priority listed down
                    below. When optional Swap-Cancels are mentioned we highly
                    recommend you attempt them, as executing them successfully
                    will make Changli gameplay much safe as well as raise her
                    damage tremendously provided you make use of the character
                    you're Swapping to via some cooldown or rotational progress
                    of their own.
                  </p>
                  <ul>
                    <li>
                      <strong>
                        If Ultimate is available and you have 0 Enflamement
                        charges (Forte Gauge) execute the following:
                      </strong>
                    </li>
                    <ul>
                      <li>Ultimate</li>
                      <li>
                        Forte: Heavy Attack: Flaming Sacrifice (Optionally
                        Swap-Cancel)
                      </li>
                    </ul>
                    <li>
                      <strong>
                        If Enflamement is full (Forte Gauge) immediately execute
                        the following:
                      </strong>
                    </li>
                    <ul>
                      <li>
                        Forte: Heavy Attack: Flaming Sacrifice (Optionally
                        Swap-Cancel)
                      </li>
                    </ul>
                  </ul>
                  <p>
                    Past this point Changli has 2 different ways she can execute
                    her main combos: a "Basic" approach or the "Double intro"
                    approach. The Basic approach allows Changli to generate
                    Enflamement stacks more freely, but deals lower DPS and
                    leaves her with vulnerablities at specific points in combat
                    (we'll mention these vulnerabilities and when they occur).
                    The "Double Intro" approach is higher DPS but restricts the
                    amount of Enflamement stacks Changli can generate, switching
                    focus onto her teammates who have to generate the Intros to
                    use and will need to fill any field time Changli isn't
                    using, this combo has very few vulnerabilities though. Both
                    approaches make use of the above.
                  </p>
                  <p>
                    <strong>Basic Approach</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>
                        Upon activating Changli's Intro execute the following
                      </strong>{' '}
                      (this combo can be challenging to land on moving targets
                      and leaves Changli exposed to Interruption and hits during
                      the Mid-Air and Basic Attack portion of the combo):
                    </li>
                    <ul>
                      <li>Intro</li>
                      <li>Basic Attack: True Sight: Charge</li>
                      <li>Mid-Air Heavy Attack</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                    </ul>
                    <li>
                      <strong>
                        When not executing any of the above, you should execute
                        the following combo instead. Keeping 1 charge (keep both
                        on CD to be safe) of Changli's skill on cooldown at all
                        times should be a priority for your team:
                      </strong>
                    </li>
                    <ul>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally Swap
                        Cancel)
                      </li>
                    </ul>
                  </ul>
                  <p>
                    <strong>Double Intro Approach</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>
                        Upon activating Changli's Intro execute the following:
                      </strong>
                    </li>
                    <ul>
                      <li>Intro</li>
                      <li>Basic Attack: True Sight: Charge</li>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally Swap
                        Cancel)
                      </li>
                    </ul>
                    <li>
                      <strong>
                        If no Intro Skill is available soon and Changli has 2
                        charges of her skill, you should not waste the cooldown
                        timer, instead execute the following combo. However,
                        outside of this rare situation holding Changli's skill
                        charges for Intro's is essential for the Double Intro
                        approach.
                      </strong>
                    </li>
                    <ul>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally Swap
                        Cancel)
                      </li>
                    </ul>
                  </ul>
                  <p>
                    The Basic Approach relies on Changli's Intro and Basic
                    Attack sequence to generate Enflamement on top of her skill
                    charges but leaves her open to Interruption and increases
                    her field time consumption with lower damage combos. The
                    Double Intro combo, on the other hand requires lower field
                    time, is safer but consumes a skill charge every intro
                    lowering total possible Enflamements during combat. Each
                    approach has its Pros and Cons and if you'd prefer, a mix of
                    both can be used in combat depending on the team you're
                    using and how many Intro's you can generate for Changli in
                    any given fight.
                  </p>
                  <ul>
                    <li>
                      <strong>
                        In the event you land a Dodge Counter Changli can skip
                        straight to her Basic P3 allowing for an alternate True
                        Sight combo. This combo not only deals low damage but
                        also exposes Changli to danger, as such this should only
                        be utilized if you have no cooldowns available on
                        Changli or any other character on the team (an extremely
                        rare occurrence):
                      </strong>
                    </li>
                    <ul>
                      <li>Dodge Counter</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap=Cancel)
                      </li>
                    </ul>
                  </ul>
                  <blockquote>
                    <h6>BASIC APPROACH COMBO</h6>
                    <p>
                      Below is a sample of how Changli's kit can play out in
                      battle, keep in mind as a Quick-Swap cooldown based
                      character you should follow the Ability Priority above
                      first and foremost, but can refer to following to get an
                      idea of what a full 100 Concerto Rotation would look like
                      for her.
                    </p>
                    <ul>
                      <li>Inferno Rider Echo (Swap After 3rd Slash Begins)</li>
                      <li>Intro</li>
                      <li>Basic Attack: True Sight: Charge</li>
                      <li>Mid-Air Heavy Attack</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                      <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                      <li>Ultimate</li>
                      <li>
                        Forte: Heavy Attack: Flaming Sacrifice (Swap-Cancel)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>DOUBLE INTRO COMBO</h6>
                    <p>
                      Alternatively here is what a Double Intro combo would look
                      like in battle.
                    </p>
                    <ul>
                      <li>Inferno Rider Echo (Swap After 3rd Slash Begins)</li>
                      <li>Intro</li>
                      <li>Basic Attack: True Sight: Charge</li>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                      <li>Intro</li>
                      <li>Basic Attack: True Sight: Charge</li>
                      <li>Skill: True Sight: Capture </li>
                      <li>
                        Basic Attack: True Sight: Conquest (Optionally
                        Swap-Cancel)
                      </li>
                      <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                      <li>Ultimate</li>
                      <li>
                        Forte: Heavy Attack: Flaming Sacrifice (Swap-Cancel)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'jinhsi' && (
                <>
                  <p>
                    <strong>COMBO REQUIREMENTS</strong>
                  </p>
                  <p>
                    Unlike most other characters Jinhsi is not reliant on
                    Ultimate or Forte Gauge burst windows - she is instead
                    cooldown-based and as such only needs to work around those,
                    which can be easily fit into a followable rotation. One
                    thing Jinhsi does require in order to execute her rotation,
                    is to perform her Intro each and every time before she
                    starts dealing her damage. Because of this requirement it's
                    imperative you build up Concerto on another character before
                    beginning her rotation (or else it wont work).
                  </p>
                  <h6>Rotation</h6>
                  <p>
                    Jinhsi's kit and rotation look hard but are actually
                    incredibly easy to play and understand. Here are the key
                    things you need to know when playing Jinhsi as well as some
                    extra info, tips and tricks.
                  </p>
                  <ul>
                    <li>
                      A large portion of Jinhsi's kit is not required when
                      playing her - specifically her standard Resonance Skill
                      and standard Basic Attacks. You'll almost never be using
                      these so you can ignore them.
                    </li>
                    <li>
                      The reason you can ignore the above is because when
                      executing her full rotation you can skip half of it by
                      executing her Intro. As using her Intro allows you to move
                      straight to Jinhsi's harder hitting abilities raising her
                      damage considerably. The result of this though is that you
                      always want to start off any Jinhsi rotation with her
                      Intro and never Swap to her until you have it.
                    </li>
                    <li>
                      When playing Jinhsi, generate enough Concerto on another
                      character first then Swap to Jinhsi performing her Intro.
                      After that use your Echo (if it's Jue) and Ultimate if
                      it's available immediately, then use Jinhsi's Resonance
                      Skill twice (it will be glowing to indicate it's ready and
                      empowered). After that use 4 Basic Attacks - this will
                      enable the final part of Jinhsi's rotation: another
                      empowered Resonance Skill which can you'll see glowing as
                      soon as it's available. After this switch back out to your
                      next desired character and you're done.
                    </li>
                    <li>
                      When executing Jinhsi's 4 Basic Attacks as a part of her
                      rotation her final empowered Resonance Skill will become
                      available before the 4th Basic Attack deals any damage. If
                      you activate it as soon as it's available you'll lose the
                      damage from the 4th Basic Attack - it can be worth it to
                      hold off on immediate activation and ensure the 4th Basic
                      Attack fully completes (the animation of the 4th Basic is
                      of a big Dragon diving at enemies).
                    </li>
                    <li>
                      Due to Jinhsi's short rotations, higher energy cost and
                      emphasis on low teammate field time she can't access her
                      Ultimate every single rotation even with high energy
                      regeneration. Due to this Jinhsi's full rotation listed
                      below consists of a big damage rotation including her
                      Ultimate and echo and a smaller damage rotation not
                      containing them. The rotations howerver are functionally
                      the same outside of the Ultimate and Echo activation!
                    </li>
                    <li>
                      When playing Jinhsi in teams don't spend to much time on
                      your other characters - Jinhsi's output is only limited by
                      her cooldowns which aren't that long. A good rule of thumb
                      is that whenever either of your characters has their Outro
                      ready, it's time to Swap back to Jinhsi and perform one of
                      her rotations.
                    </li>
                    <li>
                      When playing Jinhsi's teams, ensure you try to keep the
                      team buffs up but more importantly ensure you utilize
                      their cooldowns and if applicable keep their coordinated
                      attacks active as much as possible.
                    </li>
                  </ul>
                  <blockquote>
                    <h6>Rotation</h6>
                    <ul>
                      <li>Intro</li>
                      <li>
                        Echo (Use immediately if Jue or before combo and
                        Swap-Cancel if Aix)
                      </li>
                      <li>Ultimate</li>
                      <li>Skill: Overflowing Radiance</li>
                      <li>Incarnation: Skill: Crescent Divinity</li>
                      <li>Incarnation: Basic P1</li>
                      <li>Incarnation: Basic P2</li>
                      <li>Incarnation: Basic P3</li>
                      <li>Incarnation: Basic P4</li>
                      <li>Skill: Illuminous Ephiphany</li>
                      <li>
                        Outro (Swap to another character to build Concerto and
                        wait for cooldowns)
                      </li>
                      <li>Intro</li>
                      <li>Skill: Overflowing Radiance</li>
                      <li>Incarnation: Skill: Crescent Divinity</li>
                      <li>Incarnation: Basic P1</li>
                      <li>Incarnation: Basic P2</li>
                      <li>Incarnation: Basic P3</li>
                      <li>Incarnation: Basic P4</li>
                      <li>Skill: Illuminous Ephiphany Star DMG</li>
                      <li>Skill: Illuminous Ephiphany Sun DMG</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'rover-havoc' && (
                <>
                  <blockquote>
                    <h6>WARM UP</h6>
                    <p>
                      While waiting for cooldowns to reset, building energy for
                      Havoc Rover and cycling through your team for Concerto
                      generation, aim to execute the following warm-up moves:
                    </p>
                    <ul>
                      <li>
                        Your top priority when playing Havoc Rover is ensuring
                        usage of Resonance Skill on cooldown while being between
                        Umbra burst windows. Using at least 1 Resonance Skill in
                        the warm-up phase before your Umbra phase allows you to
                        skip out on needing to perform repeated un-enhanced
                        Basic Attacks to build the Forte Gauge, while also
                        dealing more damage and more importantly generating more
                        Resonance and Concerto energy. We also recommend you
                        weave Resonance Skill in between your other team mates
                        rotations and abilities while waiting for your next
                        burst combo.
                      </li>
                      <li>
                        In addition to using your Resonance Skill on cooldown,
                        another thing to focus on is hitting some enemies here
                        and there with Basic Attacks, moreso if you're using the
                        5P Havoc set. Doing so will not only keep all of your
                        buffs active but also build additional Resonance and
                        Concerto energy.
                      </li>
                    </ul>
                  </blockquote>
                  <p>
                    Havoc Rover has two different burst combos to choose from
                    after unlocking their 2nd Sequence - both are viable and
                    have their own Pros, Cons and playstyles. Also something
                    worth noting is that while Havoc Rover certainly benefits
                    from a structured burst window having abilities in perfect
                    sequence, in order to gain them damage boosts during Umbra
                    State, it is still perfectly viable to use their abilities
                    in a less structured manner - albeit with a noticeable
                    damage loss. But it's much better to ensure that you don't
                    waste energy, cooldowns or Umbra rather than trying to line
                    up a perfect burst window every single time.
                  </p>
                  <p>
                    The only hard rule we recommend is, aiming to always pair
                    usage of Dreamless following Ultimate usage (of course, with
                    more benefits if used during Umbra State). Despite there
                    being two distinct combos listed, in practice you may find
                    yourself using a combination of both instead owing to the
                    situation in hand.
                  </p>
                  <blockquote>
                    <h6>LONG BURST COMBO</h6>
                    <p>
                      Havoc Rover's long burst combo deals higher total damage
                      at the cost of longer duration required to execute it, It
                      is primarily suitable for team's where they are the sole
                      DPS accompanied by two Supportive characters. As long as
                      warm-up is executed, you can move straight into Umbra
                      state following your Intro and standard skill. After that,
                      aim to execute 2 full Basic Attack sequences followed by
                      an Umbra skill to extend the Umbra state. Then comes using
                      your Ultimate skill followed by Dreamless Echo into
                      Swap-Cancel in the remaining Umbra state time.
                    </p>
                    <ul>
                      <li>Intro</li>
                      <li>Skill</li>
                      <li>Heavy Attack: Devastation</li>
                      <li>Umbra: Basic P1</li>
                      <li>Umbra: Basic P2</li>
                      <li>Umbra: Basic P3</li>
                      <li>Umbra: Basic P4</li>
                      <li>Umbra: Basic P5</li>
                      <li>Umbra: Basic P1</li>
                      <li>Umbra: Basic P2</li>
                      <li>Umbra: Basic P3</li>
                      <li>Umbra: Basic P4</li>
                      <li>Umbra: Basic P5</li>
                      <li>Skill: Umbra: Lifetaker (Optionally Swap-Cancel)</li>
                      <li>Ultimate</li>
                      <li>Echo (Dreamless Swap-Cancel)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>SHORT BURST COMBO</h6>
                    <p>
                      The Short Burst Combo deals majority of its damage in
                      short duration burst combo, and thus is much more suitable
                      for Quick-Swap teams, containing another DPS in a team
                      that desires field time for their own combo's execution.
                      This combo completely eliminates all the Basic Attack
                      sequences and instead focuses on utilizing abilities with
                      highest damage, able to be executed in shortest time with
                      least amount of vulnerabilities.
                    </p>
                    <ul>
                      <li>Intro</li>
                      <li>Skill</li>
                      <li>Heavy Attack: Devastation</li>
                      <li>Skill: Umbra: Lifetaker (Optionally Swap-Cancel)</li>
                      <li>Ultimate</li>
                      <li>Echo (Dreamless Swap-Cancel)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'yuanwu' && (
                <>
                  <p>
                    Unlike most primary DPS and Hybrid dealers, Yuanwu features
                    no set rotation when played and instead focuses on his
                    Ultimate and Resonance Skill, which make up the majority of
                    his endgame capabilities. While we have assigned him a
                    “rotation” for the purpose of calculations, it is better to
                    refer to the following list as things to watch out for
                    during his gameplay instead:
                  </p>
                  <ul>
                    <li>
                      Building Yuanwu’s Forte Gauge as fast as possible should
                      be your focus as it maximizes his damage potential and
                      generates Concerto and Resonance Energy.
                    </li>
                    <li>
                      While his Thunder Wedge (skill) is active, he gains Forte
                      Gauge passively, and gains additional Forte each time he
                      lands a coordinated attack (whether after an attack by
                      himself or via ally) on an enemy in his Wedge radius. As
                      such it is essential to keep his Wedge active at all times
                      and within range of the currently targeted enemy to
                      maximize Forte generation.
                    </li>
                    <li>
                      Another benefit of keeping his Thunder Wedge active and in
                      range of target enemy is that it emits a pulse of energy
                      dealing small AoE damage upon completely filling his
                      Forte.
                    </li>
                    <li>
                      Using a charged skill on an enemy to expend filled Forte
                      Gauge is by far, the easiest way to generate Concerto and
                      Resonance Energy for him.
                    </li>
                    <li>
                      Upon executing his Ultimate or Forte Gauge empowered
                      skill, he explodes any active Thunder Wedges, thus making
                      it important to ensure it’s presence on the target you are
                      about to hit with either skill beforehand. (Thunder Wedge
                      can be summoned back shortly to minimize downtime).
                    </li>
                    <li>
                      Yuanwu’s Ultimate skill grants him Lightning-infused state
                      grants him many effects, including Anti-Interruption which
                      benefits other team members even after Swapping.
                    </li>
                    <li>
                      Yuanwu’s Ultimate should be utilized as soon as it's
                      available (preferably with detonation of a Wedge), unless
                      it's being saved to assist an teammate with
                      Anti-Interruption buff.
                    </li>
                    <li>
                      Despite Lighting Infused state being an enhanced state,
                      like many other characters possess, due to its low
                      multipliers, it's not worth keeping Yuanwu on the field to
                      utilize his Basic Attack combos.
                    </li>
                  </ul>
                  <blockquote>
                    <h6>Ability Priority</h6>
                    <ul>
                      <li>Echo </li>
                      <li>Intro (If Available)</li>
                      <li>Skill: Place Thunderwedge</li>
                      <li>Ultimate (Detonates Thunderwedge)</li>
                      <li>Skill: Forte: Rumbling Spark</li>
                      <li>Skill: Place Thunderwedge</li>
                      <li>Outro (If Available)</li>
                      <li></li>
                      <li>
                        Skill: Place Thunderwedge (As needed to keep active and
                        on top of enemies)
                      </li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'jiyan' && (
                <>
                  <blockquote>
                    <h6>WARM UP</h6>
                    <p>
                      While waiting for cooldowns to reset, building energy for
                      Jiyan and cycling through your team for Concerto
                      generation, aim to execute the following warm-up moves:
                    </p>
                    <ul>
                      <li>
                        Keep Jiyan’s Resonance Skill on cooldown always outside
                        your burst window, as it generates massive Resonance
                        Energy.
                      </li>
                      <li>
                        Jiyan’s Echo should be used in the warm-up phase but
                        right before his Ultimate usage, so you get Echo buff
                        without reducing time in his Ultimate burst window.
                      </li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>BURST COMBO</h6>
                    <p>
                      Jiyan’s enhanced Basic Attack, Lance of Qinglong only
                      deals damage, when Jiyan is executing the animation
                      associated, afterwards despite animation continuing on its
                      own, it deals no damage. As such, Dash-Canceling Lance or
                      interrupting it with another skill, allows you to exit the
                      animation state, securing any damage done so far while
                      forgoing any further damage. His Ultimate duration allows
                      for either 6 Lance’s + Skill or 7 Lance’s before being
                      left with little duration, to maximize gain from this
                      duration, we start and cancel another Lance of Qinglong,
                      gaining a bit of damage at cost of minimum rotation time.
                      It can be seen as executing 6 Lance’s, a skill and small
                      portion of 7th Lance of Qinglong with this trick, however
                      it can be ignored if desired, for it is minimal damage
                      gain.
                    </p>
                    <ul>
                      <li>
                        Echo (Use Jiyan's Echo right before entering the burst
                        combo so the Echo buff is active. Refer to the Echo
                        guide on whether you'll need to Swap-Cancel it.)
                      </li>
                      <li>Intro</li>
                      <li>Forte: Emerald Storm</li>
                      <li>
                        Heavy ATK: Lance of Qinqlong P1 (Interrupt as fast as
                        possible using Resonance Skill)
                      </li>
                      <li>Resonance Skill</li>
                      <li>Heavy Attack: Lance of Qinqlong P1</li>
                      <li>Heavy Attack: Lance of Qinqlong P2</li>
                      <li>Heavy Attack: Lance of Qinqlong P3</li>
                      <li>Heavy Attack: Lance of Qinqlong P1</li>
                      <li>Heavy Attack: Lance of Qinqlong P2</li>
                      <li>Heavy Attack: Lance of Qinqlong P3</li>
                      <li>Resonance Skill</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'danjin' && (
                <>
                  <blockquote>
                    <h6>MAINTENANCE</h6>
                    <p>
                      Due to Danjin’s kit nature and her Burst Rotation
                      flexibility, she isn't required to perform any actions
                      beforehand. However, there are certain things to aim for
                      to be maintained throughout combat, despite her not
                      needing them to achieve certain energy thresholds at
                      specific times unlike other characters.
                    </p>
                    <ul>
                      <li>
                        Danjin’s Incinerating Will should be kept active at all
                        times, to ensure damage amplification, to do this, weave
                        in the following combo throughout battle in addition to
                        Normal Burst combo listed below:
                      </li>
                      <ul>
                        <li>Basic P1 OR Skill: Carmine Gleam</li>
                        <li>Basic P2</li>
                        <li>Skill: Crimson Erosion I</li>
                        <li>Skill: Crimson Erosion II</li>
                      </ul>
                    </ul>
                    <br />
                    <p>
                      Keep using Danjin's Echo on cooldown, while using
                      Dreamless ensure to Swap-Cancel it as soon as it's
                      available. However, if using alternative Echo, such as
                      buffing one, aim to utilize it before executing a long
                      burst combo.
                    </p>
                    <p>
                      If Ultimate becomes earlier than planned, use it
                      immediately as long as Incinerating Will is active on the
                      enemy to not waste energy. However, if you are running a
                      team that aims to enhance it with Deepen Outros, stack
                      those first.
                    </p>
                  </blockquote>
                  <blockquote>
                    <h6>BURST COMBO</h6>
                    <p>
                      Danjin’s burst combo doesn’t require it to be executed
                      uninterrupted - allowing her to use Dodges or Swapping to
                      other characters before returning to finish the combo.
                      This is as her preferred Echo (Dreamless) has no buff
                      associated with it and her kit lacks any strict duration
                      to fit damage into. However, if using a team with specific
                      Deepen amplification effects that prohibit switching,
                      consider using that first before performing Swap mid
                      combo.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Skill: Crimson Erosion I</li>
                      <li>Skill: Crimson Erosion II</li>
                      <li>Ultimate</li>
                      <li>Skill: Carmine Gleam</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Skill: Sanguine Pulse I</li>
                      <li>Skill: Sanguine Pulse II</li>
                      <li>Skill: Sanguine Pulse III</li>
                      <li>Skill: Carmine Gleam</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Skill: Sanguine Pulse I</li>
                      <li>Skill: Sanguine Pulse II</li>
                      <li>Skill: Sanguine Pulse III</li>
                      <li>Heavy Attack: Chaoscleave Full</li>
                      <li>Heavy Attack: Scatterbloom Full</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'encore' && (
                <>
                  <blockquote>
                    <h6>WARM UP</h6>
                    <p>
                      When awaiting cooldowns, building energy for Encore and
                      cycling through your team for Concerto or playing Encore
                      in Quick Swap, aim to execute the following warm-up moves
                      on cooldown. Ensure all of the following abilities are
                      always utilized to maximize her damage, this is even more
                      so important when playing Encore in Quick Swap teams,
                      where she has less field time to utilize her Ultimate.
                    </p>
                    <ul>
                      <li>
                        Use Resonance Skill P1 Flaming Woolies on cooldown and
                        immediately Swap-Cancel it after casting, allowing you
                        to play another character while Encore finishes the cast
                        in an immune state.
                      </li>
                      <li>
                        Use Encore’s Echo on cooldown, especially if Inferno
                        Rider, performing any Swap-Cancels where required. When
                        playing Encore in teams with ample time for her Ultimate
                        usage, ensure Echo buff is active throughout her
                        Ultimate duration.
                      </li>
                      <li>
                        Use Charged Heavy Attack whenever Encore’s Forte Gauge
                        is full and Swap-Cancel to the next character
                        immediately. Whenever possible, align usage of Forte
                        Gauge with her Ultimate for a large increase in its
                        damage.
                      </li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>EASY & BASIC BURST COMBO</h6>
                    <p>
                      When playing teams that focus on Encore's Ultimate or when
                      you have field time to spare after all other cooldowns
                      have been expended in Quick Swap team's execute the
                      following burst combo on Encore.
                    </p>
                    <p>
                      Ending Encore’s Ultimate early to cast Rupture before
                      duration concludes can be a viable strategy in Quick Swap
                      teams, so as to not waste other DPS cooldowns. Whereas, if
                      Encore is the sole DPS that is buffed by teammates, this
                      is generally advised against.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>Skill: Cosmos: Rampage</li>
                      <li>Basic ATK: Cosmos: Frolicking P1</li>
                      <li>Basic ATK: Cosmos: Frolicking P2</li>
                      <li>Basic ATK: Cosmos: Frolicking P3</li>
                      <li>Basic ATK: Cosmos: Frolicking P4</li>
                      <li>Skill: Cosmos: Rampage</li>
                      <li>Basic ATK: Cosmos: Frolicking P1</li>
                      <li>Basic ATK: Cosmos: Frolicking P2</li>
                      <li>Basic ATK: Cosmos: Frolicking P3</li>
                      <li>Basic ATK: Cosmos: Frolicking P4</li>
                      <li>Skill: Cosmos: Rampage</li>
                      <li>
                        Heavy ATK: Cosmos: Rupture (Swap-Cancel The Moment
                        Encore Begin's Channelling)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>ADVANCED BURST COMBO</h6>
                    <p>
                      For those aiming even more damage when playing Encore and
                      are confident in their mechanical abilities, there are a
                      number of tricks you can employ to increase her damage at
                      cost of difficulty increase in battle. This is achieved
                      via executing more Basic Attacks after each Ultimate, here
                      is what you will need to adjust:
                    </p>
                    <ul>
                      <li>
                        Core of new rotates lies in cancellation of Encore’s 4th
                        enhanced Basic Attack animation, allowing you to start
                        your next action much faster. This can be achieved by
                        interrupting it via Skill usage or a Dash, you will need
                        to perform one of these cancels 3 times during her
                        Ultimate in this new rotation.
                      </li>
                      <li>
                        The other change is, this rotation gives one enhanced
                        Skill use in favor of 4 more Basic Attacks, this leads
                        to increased damage but leaves you with very less time
                        to execute the entire combo without losing out on final
                        Basic Attack, Skill and Forte Heavy Attack if you mess
                        up. up.
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>Basic ATK: Cosmos: Frolicking P1</li>
                      <li>Basic ATK: Cosmos: Frolicking P2</li>
                      <li>Basic ATK: Cosmos: Frolicking P3</li>
                      <li>Basic ATK: Cosmos: Frolicking P4</li>
                      <li>
                        Skill: Cosmos: Rampage (Use Skill to animation cancel
                        Frolicking P4)
                      </li>
                      <li>Basic ATK: Cosmos: Frolicking P1</li>
                      <li>Basic ATK: Cosmos: Frolicking P2</li>
                      <li>Basic ATK: Cosmos: Frolicking P3</li>
                      <li>
                        Basic ATK: Cosmos: Frolicking P4 (Dash Cancel this
                        attack)
                      </li>
                      <li>Basic ATK: Cosmos: Frolicking P1</li>
                      <li>Basic ATK: Cosmos: Frolicking P2</li>
                      <li>Basic ATK: Cosmos: Frolicking P3</li>
                      <li>Basic ATK: Cosmos: Frolicking P4</li>
                      <li>
                        Skill: Cosmos: Rampage (Use Skill to animation cancel
                        Frolicking P4)
                      </li>
                      <li>
                        Heavy ATK: Cosmos: Rupture (Swap-Cancel the moment
                        Encore begins channelling)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'calcharo' && (
                <>
                  <blockquote>
                    <h6>WARM UP</h6>
                    <p>
                      When awaiting cooldowns, building energy for Calcharo and
                      cycling through your team for Concerto, aim to execute the
                      following warm-up moves. Use one of these combos before
                      executing Ultimate combo to build up Resonance Energy as
                      well as enabling many Weapon bonus buffs to be active
                      during burst combo:
                    </p>
                    <p>
                      <strong>Option 1</strong> (This generates more Concerto
                      and Resonance Energy and enables more Weapon bonuses to be
                      active during burst, at the cost of higher risk.)
                    </p>
                    <ul>
                      <li>Basic P1</li>
                      <li>Skill: Extermination Order P1</li>
                      <li>Basic P2</li>
                      <li>Skill: Extermination Order P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>
                        Skill: Extermination Order P3 (Can be used to animation
                        cancel the less effective portion of Basic P4)
                      </li>
                      <li>
                        Heavy ATK: Mercy (Swap-Cancel if you can for higher
                        damage and to protect Calcharo)
                      </li>
                    </ul>
                    <br />
                    <p>
                      <strong>Option 2</strong> (faster to execute, safer and
                      can be done in between Dodging)
                    </p>
                    <ul>
                      <li>Skill: Extermination Order P1</li>
                      <li>Skill: Extermination Order P2</li>
                      <li>Skill: Extermination Order P3</li>
                      <li>
                        Heavy ATK: Mercy (Swap-Cancel if you can for higher
                        damage and to protect Calcharo)
                      </li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>OPTIMIZED BURST COMBO</h6>
                    <p>
                      Below given is an optimized combo that implements either a
                      Skill Cancel or Dash Cancel to allow faster accumulation
                      of Killing Intent by Calcharo. The slowest part of Hounds
                      Roar 5 Attack combo, required to generate 5 Killing Intent
                      to cast Death Messenger is its part 4 and 5, by using
                      Skill or Dash after 3rd part, you can reset the combo,
                      gaining last 2 charges with part 1 and 2, thus speeding up
                      your access to Death Messengers. While this combo is
                      challenging to execute in spur of the moment, it offers
                      higher chance to execute 3 Death Messengers and minimize
                      time wasted in long attack animations,
                    </p>
                    <p>
                      When playing Calcharo without Quick Swaps, we recommend
                      using Skill Cancel as it generates more Concerto and
                      Resonance Energy, while dealing more damage and being
                      easier to use. When using Swap-Cancels, Calcharo’s skill
                      will be placed on cooldown after one use, forcing use of
                      Dash Cancel to continue the combo. Both approaches are
                      valid and we suggest you try both to find what fits you
                      better, otherwise refer to the easier combo below.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>
                        Heavy ATK: Death Messenger (Optionally Swap-Cancel if
                        you can - for higher damage and to protect Calcharo)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>Basic ATK: Hounds Roar P3</li>
                      <li>
                        Skill: Extermination Order P1 OR Dash Cancel (Use Skill
                        Right after you gain a charge of the Forte)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>
                        Heavy ATK: Death Messenger (Swap-Cancel if you can - for
                        higher damage and to protect Calcharo)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>Basic ATK: Hounds Roar P3</li>
                      <li>
                        Skill: Extermination Order P2 OR Dash Cancel (Use Skill
                        right after you gain a charge of the Forte)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>
                        Heavy ATK: Death Messenger (Swap-Cancel if you can - for
                        higher damage and to protect Calcharo)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>EASY BUT RISKY BURST COMBO</h6>
                    <p>
                      If weaving in Skills between Basic Attack sequences is
                      hard for you, you can simply spam the Left Mouse button to
                      execute the combo instead. This leads to less damage, more
                      vulnerabilities, less time to execute all Death Messengers
                      but is far easier to execute.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>
                        Heavy ATK: Death Messenger (Optionally Swap-Cancel if
                        you can for higher damage and to protect Calcharo)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>Basic ATK: Hounds Roar P3</li>
                      <li>Basic ATK: Hounds Roar P4</li>
                      <li>Basic ATK: Hounds Roar P5</li>
                      <li>
                        Heavy ATK: Death Messenger (Swap-Cancel if you can for
                        higher damage and to protect Calcharo)
                      </li>
                      <li>Basic ATK: Hounds Roar P1</li>
                      <li>Basic ATK: Hounds Roar P2</li>
                      <li>Basic ATK: Hounds Roar P3</li>
                      <li>Basic ATK: Hounds Roar P4</li>
                      <li>Basic ATK: Hounds Roar P5</li>
                      <li>
                        Heavy ATK: Death Messenger (Swap-Cancel if you can for
                        higher damage and to protect Calcharo)
                      </li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="HWzEV-7nMMI" />
                    </Col>
                  </Row>
                </>
              )}
              {slug === 'chixia' && (
                <>
                  <blockquote>
                    <h6>WARM UP</h6>
                    <p>
                      While waiting for cooldowns to reset, building energy for
                      Chixia and cycling through your team for Concerto
                      generation, aim to execute the following warm-up moves:
                    </p>
                    <ul>
                      <li>
                        Use a charge of Resonance Skill on cooldown, outside of
                        burst of window to generate much required Resonance
                        Energy and alongside her Forte Gauge. As a guideline,
                        try to fit in two Resonance Skills in Warm-Up phase
                        while setting aside 1 Charge before entering burst
                        combo, unless you possess Chixia’s Sequence - 4 which
                        allows for greater flexibility in skill usage. By
                        executing 2 Resonance Skills in this phase, you will
                        generate the majority of her Forte Gauge, making her
                        ready for burst combo.
                      </li>
                      <li>
                        When executing Resonance Skills in the Warm-Up phase,
                        remember to immediately Swap-Cancel them saving you long
                        animation times.
                      </li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>BURST COMBO</h6>
                    <p>
                      <strong>
                        Chixia features one of the hardest burst combo sequences
                        to successfully complete in endgame content due to
                        multiple reasons such as:
                      </strong>{' '}
                    </p>
                    <p>
                      Using her Echo, especially if buff related Echo such as
                      Inferno Rider or Mech Abomination before initiating her
                      burst combo. With her Echo buff in place, move away from
                      target to build some distance to make landing combo easier
                      and trigger her Intro.
                    </p>
                    <p>
                      Immediately after Intro skill, use her Resonance Skill to
                      consume Forte Gauge built in Warm-Up phase. Repeat this
                      until you reach the indicator on her Forte Gauge then tap
                      Basic Attack to fire BOOM BOOM. A full execution of this
                      ability takes 4 seconds and while it allows movement,
                      failing to complete the full duration will cause massive
                      damage loss.
                    </p>
                    <p>
                      While channeling Chixia’s Forte Gauge, aim for exactly 30
                      Bullets consumption, as any longer will leave you dry for
                      the next channel. While channeling, she gains a massive
                      attack boost, which is why we use it just before her
                      Ultimate, following which use another full Forte channel
                      into BOOM BOOM and lastly Outro, before repeating the
                      whole sequence.
                    </p>
                    <p>
                      <strong>It's extremely important</strong> to note that
                      while pairing her Forte Skills and Ultimate result in
                      large damage increases, it is more important to land them
                      in first place. As such, you are free to completely ignore
                      the sequence below and instead use each ability as
                      opportunity presents itself, even if it ends up in damage
                      loss.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Skill: Forte: Thermobaric Bullets</li>
                      <li>Skill: Forte: BOOM BOOM</li>
                      <li>Ultimate</li>
                      <li>Skill: Forte: Thermobaric Bullets</li>
                      <li>Skill: Forte: BOOM BOOM</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'jianxin' && (
                <>
                  <p>
                    When playing Jianxin as a support focus on achieving the
                    following in combat:
                  </p>
                  <blockquote>
                    <ul>
                      <li>
                        Keep your Resonance Skill on cooldown at all times,
                        unless its usage interrupts use of other higher priority
                        cooldowns or DPS burst windows.
                      </li>
                      <li>
                        Ensure to perform Basic Attacks throughout combat to
                        keep 5P Rejuvenating Glow Set and Originite: Type IV
                        bonus active at all times. These attacks also help in
                        generating additional Concerto energy if aiming to
                        trigger her Outro before character Swap.
                      </li>
                      <li>
                        Use her Echo as frequently as possible asides from
                        saving it for usage just before a burst combo.
                      </li>
                      <li>
                        Always utilize Jianxin’s Ultimate to generate Concerto
                        Energy and control enemies, aside from saving it to
                        Group-Up enemies at specific point of combat.
                      </li>
                      <li>
                        If possible, aim to align Jianxin’s Outro with the main
                        DPS burst combo (provided they benefit from Resonance
                        Liberation damage) or the use of their Ultimate
                        (provided it deals direct damage) when available.
                      </li>
                      <li>
                        Use her Forte Gauge as you deem fit, it will almost
                        always result in overall damage loss, but sometimes the
                        Shield and Concerto Energy generation is worth the
                        tradeoff.
                      </li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'rover-spectro' && (
                <>
                  <blockquote>
                    <h6>COMBO REQUIREMENTS</h6>
                    <p>
                      Thanks to an incredibly accessible Forte Gauge, Ultimate
                      and very short Resonance Skill cooldown, Spectro Rover can
                      directly jump into action without any Warm-Up phase.
                    </p>
                  </blockquote>
                  <blockquote>
                    <h6>BURST COMBO</h6>
                    <p>
                      Spectro Rover requires a Quick Swap mid-rotation to unlock
                      their maximum potential. This Swap is performed to abuse
                      their extremely short Resonance Skill cooldown of only 6
                      seconds. Use one Skill initially alongside Rover’s
                      Ultimate, perform a Quick Swap and use a Skill or Echo on
                      another character and Swap back in to use another
                      Resonance Skill combo for maximum damage with little
                      downtime. This way Rover is always using their most
                      effective and highest damage action throughout the
                      majority of the fight.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Heavy Attack</li>
                      <li>Heavy Attack: Resonance</li>
                      <li>Heavy Attack: Aftertune</li>
                      <li>Skill: Forte: Resonanting Spin</li>
                      <li>Basic ATtacK: Forte: Resonating Echoes</li>
                      <li>Ultimate (Quick Swap While Waiting For Skill)</li>
                      <li>Skill: Forte: Resonanting Spin</li>
                      <li>Basic Attack: Forte: Resonating Echoes</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'aalto' && (
                <>
                  <p>
                    Aalto’s primary aim is to spend minimal field time while
                    fully generating his Concerto, in order to grant another
                    character a powerful 23% generic Aero Amplification buff
                    with least chance of failure. Due to his trickster build, he
                    has many quirks and play styles available but to best suit
                    his endgame role - we have settled with the fastest and most
                    consistent Concerto rotation. Presented below is some
                    information on how to play Aalto and how his rotation works:
                  </p>
                  <ul>
                    <li>
                      His Resonance Skill: Mist Avatar, generates absurd amounts
                      of Concerto Energy along with decent Resonance Energy and
                      taunts the enemies with a Mist Clone.
                    </li>
                    <li>
                      The quickest and most efficient method to generate his
                      Outro is to use his Skill twice alongside his Ultimate and
                      sequence of Basic Attacks. Due to his Skill having a long
                      cooldown of 10 seconds at S0, longer than any rotation he
                      would want to employ itself, it is best to weave in his
                      skill into rotation of your other support characters to
                      refresh its cooldown, thus allowing you to use it again
                      for his main Concerto rotation.
                    </li>
                    <li>
                      His Forte Skill: Mistcloak Dash, is an excellent but
                      difficult to use mobility tool in actual combat
                      situations, more so when attempting to generate Concerto
                      quickly under pressure. Given this issue and challenge of
                      generating Forte Gauge charges to begin with, we have
                      opted for no Forte rotation and found it working the best,
                      however feel free to weave in this Forte where you find it
                      to be effective if given a chance!
                    </li>
                    <li>
                      His Resonance Skill : Mist Avatar, requires a target to
                      generate most of its Concerto and Resonance Energy and
                      features a slight delay in their gain due to bullets
                      needing to hit the target first. This can lead to delay in
                      Outro and as such should be used as early in rotation as
                      possible.
                    </li>
                    <li>
                      In the given rotation, you don’t need to worry about
                      firing through or positioning for his Mist Gates as the
                      same is not required to achieve the Concerto threshold for
                      his Outro.
                    </li>
                  </ul>
                  <blockquote>
                    <ul>
                      <li>
                        Skill: Mist Avatar (Use during another character's
                        rotation to allow for cooldown)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>
                        Skill: Mist Avatar (Can be used after Basic Attacks if
                        still on Cooldown)
                      </li>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>Basic P5</li>
                      <li>Echo (Impermenance Heron)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'yangyang' && (
                <blockquote>
                  <ul>
                    <li>Intro</li>
                    <li>Resonance Liberation</li>
                    <li>Resonance Skill</li>
                    <li>Basic Attack P1</li>
                    <li>Basic Attack P2</li>
                    <li>Basic Attack P3</li>
                    <li>Basic Attack P4</li>
                    <li>Mid-Air Attack: Feather Release</li>
                    <li>
                      Heron OR Turtle Echo (Swap-Cancel after first part hits)
                    </li>
                    <li>Outro</li>
                  </ul>
                </blockquote>
              )}
              {slug === 'sanhua' && (
                <>
                  <p>
                    When playing Sanhua, you have few choices to quickly and
                    effectively build her Concerto gauge, while dealing great
                    bust damage. Below listed are two commonly used rotations
                    with the Heavy Attack variant being the preferred choice of
                    player base currently. When playing Sanhua, keep in mind the
                    following things:
                  </p>
                  <ul>
                    <li>
                      Deploy her 3 Forte Ice Constructs - 1 each from her Intro,
                      Skill and Ultimate.
                    </li>
                    <li>
                      Shatter all 3 Constructs via a Heavy Attack Detonate
                      before their expiry, as exploding each Construct generates
                      15 Concerto Energy for a combined total of 45, something
                      not worth missing).
                    </li>
                    <li>
                      Trigger her Concerto at the right moment to buff DPS of
                      choice with her 38% Basic Attack Amplification.
                    </li>
                  </ul>
                  <blockquote>
                    <h6>HEAVY ATTACK CONCERTO ROTATION</h6>
                    <ul>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>Skill</li>
                      <li>Heavy Attack: Detonate</li>
                      <li>Heavy Attack: Detonate</li>
                      <li>Echo (Impermanance Heron Swap-Cancel)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>BASIC ATTACK CONCERTO ROTATION</h6>
                    <ul>
                      <li>Intro</li>
                      <li>Basic Attack P3</li>
                      <li>Basic Attack P4</li>
                      <li>Basic Attack P5</li>
                      <li>Ultimate</li>
                      <li>Skill</li>
                      <li>Heavy Attack: Detonate</li>
                      <li>Echo (Impermanance Heron Swap-Cancel)</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'mortefi' && (
                <>
                  <p>
                    Mortefi is easy and straightforward to play when following
                    the rotation below, however keep an eye out for the
                    following things while playing him:
                  </p>
                  <ul>
                    <li>
                      Mortefi’s Ultimate and Outro should be always used
                      together right before you Swap to another character to
                      perform their burst combo (preferably a Heavy attacker).
                      Triggering his Ultimate too early or during his own
                      rotation wastes a chunk of his damage potential as well as
                      buffing potential if S6.
                    </li>
                    <li>
                      Using Resonance Skill: Passionate Variation gains you
                      additional Annoyance from all Basic Attacks for 5
                      seconds.Taking advantage of this window is important to
                      allow you regain access to Fury Fugue quickly to complete
                      his Concerto and keep rotation short.
                    </li>
                    <li>
                      His Ultimate will be always used at the end of his
                      Concerto Rotation, and generates 20 Concerto Energy (a
                      little less than a quarter of the gauge). As such, plan
                      around this and use his Ultimate when you are missing
                      approx 20 Concerto so as to minimize time spent generating
                      it.
                    </li>
                  </ul>
                  <blockquote>
                    <ul>
                      <li>Intro</li>
                      <li>Skill: Passionate Variation</li>
                      <li>Skill: Fury Fugue</li>
                      <li>Basic P1</li>
                      <li>Basic P2</li>
                      <li>Basic P3</li>
                      <li>Basic P4</li>
                      <li>Skill: Fury Fugue</li>
                      <li>Ultimate</li>
                      <li>Echo (Impermanance Heron Swap-Cancel)</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'lingyang' && (
                <>
                  <blockquote>
                    <h6>COMBO REQUIREMENTS</h6>
                    <p>
                      Unlike other characters, Lingyang can directly jump into
                      action without any Warm-Up phase. Only thing to focus
                      outside of his Ultimate burst combo is ensuring usage of
                      Echo at appropriate time, that is, if Buff Echo, use it
                      right before burst combo execution, if direct damage Echo
                      (such as Glacial Predator), use it whenever off cooldown.
                    </p>
                  </blockquote>
                  <blockquote>
                    <h6>BURST COMBO</h6>
                    <p>
                      Lingyang’s combo is incredibly simple, just make sure you
                      have full energy available on him before Swapping to him
                      and enter with his Intro to generate a portion of his
                      Forte Gauge required. Follow it up with his Ultimate and
                      perform a Heavy Attack to become airborne.
                    </p>
                    <p>
                      While airborne, alternate between using a single Basic
                      Attack and a single Resonance Skill to take advantage of
                      his Inherent Skill: Diligent Practice. Ensure that you do
                      not chain 2 of same type together while alternating
                      between them, as it is very easy to accidentally do the
                      same and lose damage overall.
                    </p>
                    <p>
                      Under ideal circumstances, you can execute 9 independant
                      attacks during his Ultimate duration, but if you get
                      Stunned or use Dodge, this number will reduce. While the
                      damage loss from this isn’t a disaster, you may need to
                      spend extra time building energy via other characters to
                      make up for it. While his Ultimate is on cooldown or he
                      lacks the energy, utilize that time on the rest of the
                      team recharging their Concerto Energy and preparing buffs
                      for his next burst combo.
                    </p>
                    <ul>
                      <li>
                        Echo (Use before combo but refer to Echo recommendations
                        for exact specifics for your chosen Echo)
                      </li>
                      <li>Intro</li>
                      <li>Ultimate</li>
                      <li>
                        Heavy Attack: Glorious Plunge (Used to enter Forte and
                        become airborne)
                      </li>
                      <li>Basic Attack: Feral Gyrate P1</li>
                      <li>Skill: Mountain Roamer</li>
                      <li>Basic Attack: Feral Gyrate P2</li>
                      <li>Skill: Mountain Roamer</li>
                      <li>Basic Attack: Feral Gyrate P1</li>
                      <li>Skill: Mountain Roamer</li>
                      <li>Basic Attack: Feral Gyrate P2</li>
                      <li>Skill: Mountain Roamer</li>
                      <li>Basic Attack: Feral Gyrate P1</li>
                      <li>Basic Attack: Stormy Kicks</li>
                      <li>Mid-Air Attack: Tail Strike</li>
                      <li>Outro</li>
                    </ul>
                  </blockquote>
                </>
              )}
              {slug === 'yinlin' && (
                <>
                  <blockquote>
                    <h6>OPENER ROTATION</h6>
                    <p>
                      Yinlin’s utmost priority in battle is to apply the
                      Punishment Mark as quickly and on as many enemies as
                      possible. To do the same, she needs to build her Forte
                      Gauge, her Intro being the best tool for it, but
                      unfortunately not always accessible (especially at the
                      start of ToA run). As such, you might consider using the
                      following rotation to quickly build up her initial Forte
                      Gauge before your team gets access to Intro’s.
                      <strong>
                        NOTE: This rotation should only be utilized when her
                        Intro is unavailable in a timely manner, which is
                        usually limited to the start of ToA runs.
                      </strong>{' '}
                    </p>
                    <ul>
                      <li>
                        Ultimate (Should be full in ToA Runs from the start)
                      </li>
                      <li>Skill: Magnetic Roar</li>
                      <li>Skill: Lightning Execution (Swap Cancel)</li>
                      <li>Heavy Attack</li>
                      <li>Heavy Attack</li>
                      <li>Chameleon Cipher (Swap Cancel)</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>STANDARD ROTATION</h6>
                    <p>
                      This rotation ensures you have 100% uptime of Punishment
                      Mark on primary target by generating Forte to access
                      Chameleon Cipher within 18 seconds (it’s duration) each
                      time:
                    </p>
                    <ul>
                      <li>
                        This and most Yinlin rotations make ample use of
                        Swap-Cancels, which improves both her damage and
                        survivability. A large part of her abilities have
                        extremely long animations, which causes damage loss and
                        chance to hit and stunned by enemies if kept on field.
                      </li>
                      <li>
                        When Swapping in Yinlin without her Intro active, she
                        will begin her Basic Attack Sequence from Part 2 instead
                        of Part 1. Her Intro, both parts of her Resonance Skill
                        and 3 Basic Attacks are ample to fill her Forte Gauge.
                        While there are many ways to build it, this is what we
                        found to be easiest and safest without utilizing her
                        Intro.
                      </li>
                      <li>
                        When Swapped out, all Sinner’s Mark applied by Yinline
                        are removed from enemies. When activating her Forte:
                        Chameleon Cipher, she can only inflict Punishment Mark
                        to enemies inflicted with Sinner’s Mark beforehand,
                        making it imperative to re-apply them to high priority
                        enemies before using her Forte Skill.
                      </li>
                      <li>
                        Using Yinlin’s Ultimate just before her Forte skill is a
                        quickfire way to widely apply Sinner’s Mark to enemies.
                      </li>
                      <li>
                        Her Forte: Chameleon Cipher can be charged during other
                        skill animations, including her Ultimate. By holding
                        Left Mouse Button (or your Heavy Attack equivalent), you
                        can skip the charge time by performing Basic Attack 1
                        before ability activation to save a lot of time.
                      </li>
                      <li>
                        All abilities we listed as Swap-Cancel, can be
                        immediately done from the very start of their animations
                        without any wait.
                      </li>
                    </ul>
                    <br />
                    <p>And here's the rotation:</p>
                    <ul>
                      <li>Echo (Swap-Cancel)</li>
                      <li>Intro</li>
                      <li>Skill: Magnetic Roar</li>
                      <li>Skill: Lightning Execution (Swap-Cancel)</li>
                      <li>Basic P2</li>
                      <li>Basic P3 </li>
                      <li>Basic P4 (Swap-Cancel)</li>
                      <li>Ultimate</li>
                      <li>Chameleon Cipher (Swap-Cancel)</li>
                    </ul>
                  </blockquote>
                  <blockquote>
                    <h6>EASY/EMERGENCY MODE ROTATION</h6>
                    <p>
                      In case you fail to grant Yinlin an Intro in midst of
                      combat, fail to perform Basic Attacks or have to utilize
                      more field time on other team mates, you may find her
                      Skill be off cooldown before fully generating your Forte
                      Gauge. If this happens and your Forte is still not close
                      to completion, you can use her Skill again, executing a
                      rotation like one listed below. However, using 2 sets of
                      Skills for 1 Forte Gauge will result in Punishment Mark
                      downtime as skill has a cooldown of 12 seconds, whereas
                      Punishment Mark lasts for 18 seconds, causing a 6 seconds
                      downtime on two sets of Skill.
                    </p>
                    <ul>
                      <li>Echo (Swap-Cancel)</li>
                      <li>Intro</li>
                      <li>Skill: Magnetic Roar</li>
                      <li>Skill: Lightning Execution (Swap-Cancel)</li>
                      <li>Skill: Magnetic Roar</li>
                      <li>Skill: Lightning Execution (Swap-Cancel)</li>
                      <li>Ultimate</li>
                      <li>Chameleon Cipher (Swap-Cancel)</li>
                    </ul>
                  </blockquote>
                </>
              )}
            </div>
          ) : (
            <>
              <Alert variant="primary alert-red">
                <p>
                  <strong>
                    Don't use the damage output calculations as an indication on
                    who is worth pulling or investing
                  </strong>{' '}
                  as those are just simulations and in day-to-day gameplay
                  there's a lot variables and also the skill of the player is
                  important - if you can't consistently perform the character's
                  rotation, their damage will suffer.
                </p>
                <p>
                  As usual,{' '}
                  <strong>don't compare the numbers between characters</strong>{' '}
                  - especially between Main DPS and Hybrids as they fulfill
                  different roles in the team.
                </p>
              </Alert>
              <h5>Additional information</h5>
              <p>
                The numbers below show the character's{' '}
                <strong>
                  DPS (Damage Per Second) and DMG (total damage done)
                </strong>
                . When selecting the rotations we prioritized achieving 100
                Concerto as fast as possible and once that condition was
                fulfilled, we focused on maximizing the damage output. As or if
                new and more optimal rotations are found, we'll update the
                characters calculations as needed. Listed character calculations
                are done without buffs or any damage contribution from teammates
                (full solo). They only include what the character, their Weapon,
                Echo and gear are capable of during their ideal Burst or
                Concerto rotation when starting with their Intro and Resonance
                Liberation available.
              </p>
              {slug === 'the-shorekeeper' && (
                <>
                  <p>
                    <strong>Important!</strong> Sequence calculations have been
                    configured to not only ensure that Shorekeeper always has
                    250% Energy Regeneration when using her Resonance
                    Liberation, but also maximize the damage. At Sequence 3, she
                    gains additional Concerto Energy shortening her rotation -
                    this Sequence impact is dependent on the Rectifier she uses.
                    With an S1 Variation or her Signature, you can remove some
                    Basic Attacks from her standard rotation and omit them
                    altogether with higher ranks of these weapons.
                  </p>
                  <p>
                    With this in mind, she can drastically reduce her field time
                    requirements resulting in very quick execution of her Intro,
                    Resonance Skill, Resonance Liberation and finally her Outro,
                    often resulting in incredibly high damage. However, keep in
                    mind that these damage numbers can’t be sustained and are a
                    result of very low field time combined with high burst
                    damage potential due to her empowered Intro.
                  </p>
                </>
              )}
              {slug === 'zhezhi' && (
                <>
                  <p>
                    <strong>Important!</strong> In the Sequence calculations
                    below we have assumed you'll be fightning enemies for at
                    least 2 or more Zhezhi rotations and will be able to
                    maintain and refresh her buffs that last for 27 - 30 seconds
                    at a time without letting them expire. For shorter fights or
                    if you're not confident keeping buffs active you can
                    consider Sequence 1, 3 and 4 to be less valuable than is
                    displayed as they'll have lower uptime for you than we
                    assume.
                  </p>
                </>
              )}
              {slug === 'yinlin' && (
                <>
                  <p>
                    <strong>Important!</strong> Due to the incredibly short
                    nature of Yinlin's rotation when implementing Swap-Cancels,
                    her DPS numbers are incredibly high. If you are unable to
                    perform Swap-Cancels her DPS will be lower,{' '}
                    <strong className="red">
                      Please also pay close attention to S2 as it makes her
                      already short rotation even shorter - this is the reason
                      it results in such a tremendous damage gains but smaller
                      total damage numbers.
                    </strong>
                  </p>
                </>
              )}
              {slug === 'jinhsi' && (
                <>
                  <p>
                    <strong>Important!</strong> Jinhsi’s Sequences have a few
                    unique properties which make them more or less useful in
                    certain scenarios. Here is what you need to be aware of:
                  </p>
                  <ul>
                    <li>
                      Jinhsi’s Sequence 2 grants her a full Forte Gauge at the
                      start of combat where as she would normally start at 0.
                      Unfortunately in ToA you cannot make use of this Sequence
                      more than once, meaning outside of the very first rotation
                      it loses its value. The result is that this Sequence gains
                      or loses power depending on how long a fight is, if an
                      enemy dies on your very first rotation - it’s an excellent
                      Sequence offering a tremendous boost but if it takes 7 or
                      8 rotations this Sequences loses a colossal amount of its
                      value. For this reason we haven’t considered it in our
                      calculations and instead have chosen to post this
                      disclaimer.
                    </li>
                    <li>
                      Jinhsi’s S3 has similar properties to her S2 but in
                      reverse. It grants a 25% Attack boost which stacks twice
                      with you gaining 1 stack per rotation. The longer the
                      fight, the more valuable this Sequence becomes but in
                      shorter battles it loses value. Unlike Jinhsi’s S2 though,
                      the ramp-up time is swift only requiring 2 rotations, and
                      it only really loses power if you’re against enemies that
                      die within 1 or 2 rotations meaning in Tower of Adversary
                      it will be at close to full power for the majority of
                      combat. For this reason we’ve included this Sequence in
                      calculations assuming you’ll be up against enemies that
                      will last for more than a couple rotations.
                    </li>
                    <li>
                      Jinhsi's rotation damage figures have been calculated
                      assuming you'll gain an average of 40 Incandecent Forte
                      Gauge stacks per rotation. Higher stack numbers will
                      increase total damage and lower stack sizes will reduce
                      it.
                    </li>
                  </ul>
                </>
              )}
              <Row xs={1} xl={1} className="dps-comparison">
                <Col>
                  <div className="box">
                    <h5>{dpsRanking.name} in 1 Target scenario</h5>
                    <p className="summary">
                      Rotation time:{' '}
                      <strong className={element}>{dpsRanking.time}s</strong>{' '}
                      {slug === 'yinlin' && (
                        <>
                          (Post S2: <strong className={element}>2.86s</strong>)
                        </>
                      )}
                      {slug === 'the-shorekeeper' && (
                        <>
                          (Post S3: <strong className={element}>1.26s</strong>)
                        </>
                      )}
                    </p>
                    <div className={`simulation-container ${slug}`}>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S6)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-1`}
                              style={{ width: '100%' }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                {dpsRanking.dmg.damage_e6.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e6.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e6 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S5)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-2`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage_e5 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                {dpsRanking.dmg.damage_e5.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e5.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e5 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S4)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-3`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage_e4 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps">
                                {dpsRanking.dmg.damage_e4.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e4.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e4 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S3)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-4`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage_e3 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps dark">
                                {dpsRanking.dmg.damage_e3.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e3.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e3 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S2)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-5`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage_e2 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps dark">
                                {dpsRanking.dmg.damage_e2.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e2.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e2 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S1)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-6`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage_e1 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps dark">
                                {dpsRanking.dmg.damage_e1.toLocaleString()} DMG
                                ({dpsRanking.dps.damage_e1.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps.damage_e1 /
                                    dpsRanking.dps.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="simulation-row">
                        <div className="character">
                          <WWCharacter
                            slug={dpsRanking.slug}
                            mode="icon"
                            enablePopover
                          />
                        </div>
                        <div className="chart">
                          <div className="info-2">
                            <p>{dpsRanking.name} (S0)</p>
                          </div>
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar top-7`}
                              style={{
                                width:
                                  (dpsRanking.dps.damage /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data">
                              <span className="dps dark">
                                {dpsRanking.dmg.damage.toLocaleString()} DMG (
                                {dpsRanking.dps.damage.toLocaleString()} DPS)
                              </span>
                              <span className="percent">100.00%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {slug === 'calcharo' && (
                <>
                  <h5>Additional information</h5>
                  <p>
                    Here is the damage difference between the two different
                    rotation's that can be used on Calcharo. The Basic rotation
                    is the standard full string of 5 Basic Attacks per Death
                    Messenger during Calcharo's Ultimate whereas the Advanced
                    rotation involves performing 3 Basics then a skill to reset
                    the Basic string then 2 more Basics. The Advanced rotation
                    is faster and leaves Calcharo less vulnerable while also
                    dealing more damage but generates slightly less Concerto
                    Energy and isn't as simple.
                  </p>
                  <Row xs={1} xl={1} className="dps-comparison">
                    <Col>
                      <div className="box">
                        <h5>Advanced vs basic rotation</h5>
                        <div className={`simulation-container ${slug}`}>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (Advanced)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-1`}
                                  style={{ width: '100%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">32,671 DPS</span>
                                  <span className="percent">108.26%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (Basic)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-3`}
                                  style={{ width: '92.37%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">30,178 DPS</span>
                                  <span className="percent">100%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <p>
                    As we've received quite a lot of questions about the
                    difference between fitting in 3 Death Messengers into the
                    rotation compared to only 2 (at S0):
                  </p>
                  <Row xs={1} xl={1} className="dps-comparison">
                    <Col>
                      <div className="box">
                        <h5>3DM vs 2DM</h5>
                        <div className={`simulation-container ${slug}`}>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (3DM)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-1`}
                                  style={{ width: '100%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">389,113 DMG</span>
                                  <span className="percent">100%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (2DM)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-3`}
                                  style={{ width: '71.2%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">277,031 DMG</span>
                                  <span className="percent">71.2%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <p>
                    You gain substantially more Energy and Concerto when pulling
                    off the 3 Death Messengers, so make sure to check out the
                    Energy requirements section for more info on how to account
                    for this.
                  </p>
                  {/* <p>
                    And lastly, here's how Calcharo's DPS improves if you can
                    switch cancel all DM vs just switch cancelling the Echo (at
                    S0):
                  </p>
                  <Row xs={1} xl={1} className="dps-comparison">
                    <Col>
                      <div className="box">
                        <h5>Full Cancel Swap vs Echo Cancel Swap</h5>
                        <div className={`simulation-container ${slug}`}>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (Full Cancel)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-1`}
                                  style={{ width: '100%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">
                                    59,772 DPS | 6.51s
                                  </span>
                                  <span className="percent">182.95%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (Echo Cancel)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-3`}
                                  style={{ width: '54.66%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">
                                    32,671 DPS | 11.91s
                                  </span>
                                  <span className="percent">100%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                </>
              )}
              {slug === 'yinlin' && (
                <>
                  <h5>Additional information</h5>
                  <p>
                    Here's a comparison between Swap-Cancelling different parts
                    of Yinlin rotation. Compared to Calcharo, Swap-Cancelling
                    her is much easier and that's why the 'Full Cancel' variant
                    is used by us as the default one as everyone can learn how
                    to do it properly with some practice.
                  </p>
                  <Row xs={1} xl={1} className="dps-comparison">
                    <Col>
                      <div className="box">
                        <h5>Swap Meter</h5>
                        <div className={`simulation-container ${slug}`}>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (ECHO + SKILL + CHAMELEON +
                                  B4)
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-1`}
                                  style={{ width: '100%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">58,894 DPS</span>
                                  <span className="percent">100%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (ECHO + SKILL + CHAMELEON)
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-2`}
                                  style={{ width: '84.19%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">49,582 DPS</span>
                                  <span className="percent">84.19%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (ECHO + SKILL)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-3`}
                                  style={{ width: '63.2%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">37,222 DPS</span>
                                  <span className="percent">63.2%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>{dpsRanking.name} (ECHO ONLY)</p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-4`}
                                  style={{ width: '50.39%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">29,674 DPS</span>
                                  <span className="percent">50.39%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {slug === 'changli' && (
                <>
                  <h5>Additional information</h5>
                  <p>
                    Changli has multiple points within a usual combat rotation
                    in which she can execute Swap-Cancels. At a minimum we
                    recommend Swap-Cancelling her final Forte Heavy Attack:
                    Flaming Sacrifice, used after her Ultimate and her Inferno
                    Rider Echo. Outside of this Changli can optionally choose to
                    Swap-Cancel all of her True Sight Conquest casts to better
                    protect herself in battle and gain tremendous damage. Below
                    you can observe the damage increases and decreases
                    associated with doing so. As a baseline we expect most
                    players to execute at least 2 Conquest Swap-Cancels.
                  </p>
                  <Row xs={1} xl={1} className="dps-comparison">
                    <Col>
                      <div className="box">
                        <h5>Swap Meter</h5>
                        <div className={`simulation-container ${slug}`}>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (3 Conquest + Post Ult Heavy
                                  + Echo)
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-1`}
                                  style={{ width: '100%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">43,920 DPS</span>
                                  <span className="percent">112.39%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (2 Conquest + Post Ult Heavy
                                  + Echo [Base])
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-2`}
                                  style={{ width: '88.98%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">39,079 DPS</span>
                                  <span className="percent">100.00%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (1 Conquest + Post Ult Heavy
                                  + Echo)
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-3`}
                                  style={{ width: '80.14%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">35,199 DPS</span>
                                  <span className="percent">90.07%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="simulation-row">
                            <div className="character">
                              <WWCharacter
                                slug={dpsRanking.slug}
                                mode="icon"
                                enablePopover
                              />
                            </div>
                            <div className="chart">
                              <div className="info-2">
                                <p>
                                  {dpsRanking.name} (Post Ult Heavy + Echo
                                  [Minimum])
                                </p>
                              </div>
                              <div className="dps-bar-outside">
                                <div
                                  className={`dps-bar top-4`}
                                  style={{ width: '72.91%' }}
                                ></div>
                                <div className="data">
                                  <span className="dps">32,020 DPS</span>
                                  <span className="percent">81.94%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <h5>Details about the calculations</h5>
              {dpsRanking.disclaimer != '' && (
                <p>
                  <strong>Disclaimer:</strong> {dpsRanking.disclaimer}
                </p>
              )}
              <Accordion
                defaultActiveKey="0"
                className="default-look dps-details"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Rotation{' '}
                    <strong className={element}>
                      &nbsp;[{dpsRanking.time}s]
                    </strong>
                    {slug === 'the-shorekeeper' && (
                      <>
                        - post S3: <strong className={element}>1.26s</strong>
                      </>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Here's the{' '}
                      <strong>
                        rotation we have used to calculate the DPS
                      </strong>{' '}
                      (Damage Per Second) shown above. The total time needed to
                      perform the rotation is{' '}
                      <strong className={element}>
                        {dpsRanking.time} seconds
                      </strong>
                      .
                    </p>
                    {slug === 'the-shorekeeper' && (
                      <>
                        <blockquote>
                          <h6>Standard Rotation</h6>
                          <ul>
                            <li>Intro Skill: Discernment</li>
                            <li>Basic P1</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>Basic P4</li>
                            <li>Forte: Heavy Atk: Illation</li>
                            <li>Skill: Chaos Theory</li>
                            <li>Echo</li>
                            <li>Liberation</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'xiangli-yao' && (
                      <>
                        <blockquote>
                          <h6>Combo</h6>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate: Cogiation Model</li>
                            <li>Ultimate: Skill: Divergence</li>
                            <li>Forte: Mid- Air Attack: Revamp</li>
                            <li>
                              Forte: Skill: Law of Reigns (Optionally
                              Swap-Cancel)
                            </li>
                            <li>Ultimate: Basic: Pivot - Impale P1</li>
                            <li>Ultimate: Basic: Pivot - Impale P2</li>
                            <li>Ultimate: Basic: Pivot - Impale P3</li>
                            <li>
                              Forte: Skill: Law of Reigns (Optionally
                              Swap-Cancel)
                            </li>
                            <li>Ultimate: Skill: Divergence</li>
                            <li>Forte: Mid- Air Atk: Revamp </li>
                            <li>Forte: Skill: Law of Reigns (Swap-Cancel)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'zhezhi' && (
                      <>
                        <blockquote>
                          <h6>Rotation</h6>
                          <ul>
                            <li>Intro Skill</li>
                            <li>Basic P1</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>
                              Resonance Skill: Manifestation: (Tap to Execute
                              Ground combo, Hold To Execute Air Combo)
                            </li>
                            <li>Forte: Heavy Atk: Conjuration</li>
                            <li>Forte: Resonance Skill: Stroke of Genius</li>
                            <li>Forte: Resonance Skill: Stroke of Genius</li>
                            <li>Forte: Resonance Skill: Creations Zenith</li>
                            <li>Ultimate: Living Canvas</li>
                            <li>
                              Echo: Impermenance Heron (Swap-Cancel immediately
                              after activation)
                            </li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'changli' && (
                      <>
                        <blockquote>
                          <h6>Rotation</h6>
                          <p>
                            Below is a sample of how Changli's kit can play out
                            in battle, keep in mind as a quick swap cooldown
                            based character you should follow the ability
                            priority above first and foremost but can refer to
                            below to get an idea of what a full 100 concerto
                            rotation looks like for her.
                          </p>
                          <ul>
                            <li>
                              Inferno Rider Echo (Swap After 3rd Slash Begins)
                            </li>
                            <li>Intro</li>
                            <li>Basic Attack: True Sight: Charge</li>
                            <li>Mid-Air Heavy Attack</li>
                            <li>Basic P3</li>
                            <li>Basic P4</li>
                            <li>
                              Basic Attack: True Sight: Conquest (Optionally
                              Swap-Cancel)
                            </li>
                            <li>Skill: True Sight: Capture </li>
                            <li>
                              Basic Attack: True Sight: Conquest (Optionally
                              Swap-Cancel)
                            </li>
                            <li>Skill: True Sight: Capture </li>
                            <li>
                              Basic Attack: True Sight: Conquest (Optionally
                              Swap-Cancel)
                            </li>
                            <li>Forte: Heavy Attack: Flaming Sacrifice</li>
                            <li>Ultimate</li>
                            <li>
                              Forte: Heavy Atk: Flaming Sacrifice (Swap-Cancel)
                            </li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'jinhsi' && (
                      <>
                        <p>
                          <strong>COMBO REQUIREMENTS</strong>
                        </p>
                        <p>
                          Unlike most other characters Jinhsi is not reliant on
                          Ultimate or Forte Gauge burst windows - she is instead
                          cooldown-based and as such only needs to work around
                          those, which can be easily fit into a followable
                          rotation. One thing Jinhsi does need in order to
                          execute her rotation though, is to perform her Intro
                          each and every time before she starts dealing her
                          damage. Because of this requirement it's imperative
                          you build up Concerto on another character before
                          beginning her rotation (or else it wont work).
                        </p>
                        <blockquote>
                          <h6>Rotation</h6>
                          <ul>
                            <li>Intro</li>
                            <li>
                              Echo (Use immediately if Jue or before combo and
                              swap cancel if Aix)
                            </li>
                            <li>Ultimate</li>
                            <li>Skill: Overflowing Radiance</li>
                            <li>Incarnation: Skill: Crescent Divinity</li>
                            <li>Incarnation: Basic P1</li>
                            <li>Incarnation: Basic P2</li>
                            <li>Incarnation: Basic P3</li>
                            <li>Incarnation: Basic P4</li>
                            <li>Skill: Illuminous Ephiphany</li>
                            <li>
                              Outro (Swap to another character to build concerto
                              and wait for cooldowns)
                            </li>
                            <li>Intro</li>
                            <li>Skill: Overflowing Radiance</li>
                            <li>Incarnation: Skill: Crescent Divinity</li>
                            <li>Incarnation: Basic P1</li>
                            <li>Incarnation: Basic P2</li>
                            <li>Incarnation: Basic P3</li>
                            <li>Incarnation: Basic P4</li>
                            <li>Skill: Illuminous Ephiphany Star DMG</li>
                            <li>Skill: Illuminous Ephiphany Sun DMG</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'rover-havoc' && (
                      <>
                        <blockquote>
                          <h6>LONG BURST COMBO</h6>
                          <p>
                            Havoc Rover's long burst combo deals higher total
                            damage at the cost of longer duration required to
                            execute it, It is primarily suitable for team's
                            where they are the sole DPS accompanied by two
                            Supportive characters. As long as warm-up is
                            executed, you can move straight into Umbra state
                            following your Intro and standard skill. After that,
                            aim to execute 2 full Basic Attack sequences
                            followed by an Umbra skill to extend the Umbra
                            state. Then comes using your Ultimate skill followed
                            by Dreamless Echo into Swap-Cancel in the remaining
                            Umbra state time.
                          </p>
                          <ul>
                            <li>Intro</li>
                            <li>Skill</li>
                            <li>Heavy ATK: Devastation</li>
                            <li>Umbra: Basic P1</li>
                            <li>Umbra: Basic P2</li>
                            <li>Umbra: Basic P3</li>
                            <li>Umbra: Basic P4</li>
                            <li>Umbra: Basic P5</li>
                            <li>Umbra: Basic P1</li>
                            <li>Umbra: Basic P2</li>
                            <li>Umbra: Basic P3</li>
                            <li>Umbra: Basic P4</li>
                            <li>Umbra: Basic P5</li>
                            <li>
                              Skill: Umbra: Lifetaker (Optionally Swap-Cancel)
                            </li>
                            <li>Ultimate</li>
                            <li>Echo (Dreamless Swap-Cancel)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                        <blockquote>
                          <h6>SHORT BURST COMBO</h6>
                          <p>
                            The Short Burst Combo deals majority of its damage
                            in short duration burst combo, and thus is much more
                            suitable for Quick Swap teams, containing another
                            DPS in a team that desires field time for their own
                            combo's execution. This combo completely eliminates
                            all the Basic Attack sequences and instead focuses
                            on utilizing abilities with highest damage, able to
                            be executed in shortest time with least amount of
                            vulnerabilities.
                          </p>
                          <ul>
                            <li>Intro</li>
                            <li>Skill</li>
                            <li>Heavy Attack: Devastation</li>
                            <li>
                              Skill: Umbra: Lifetaker (Optionally Swap-Cancel)
                            </li>
                            <li>Ultimate</li>
                            <li>Echo (Dreamless Swap-Cancel)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'jiyan' && (
                      <>
                        <blockquote>
                          <h6>BURST COMBO</h6>
                          <p>
                            Jiyan’s enhanced Basic Attack, Lance of Qinglong
                            only deals damage, when Jiyan is executing the
                            animation associated, afterwards despite animation
                            continuing on its own, it deals no damage. As such,
                            Dash-Canceling Lance or interrupting it with another
                            skill, allows you to exit the animation state,
                            securing any damage done so far while forgoing any
                            further damage. His Ultimate duration allows for
                            either 6 Lance’s + Skill or 7 Lance’s before being
                            left with little duration, to maximize gain from
                            this duration, we start and cancel another Lance of
                            Qinglong, gaining a bit of damage at cost of minimum
                            rotation time. It can be seen as executing 6
                            Lance’s, a skill and small portion of 7th Lance of
                            Qinglong with this trick, however it can be ignored
                            if desired, for it is minimal damage gain.
                          </p>
                          <ul>
                            <li>
                              Echo (Use Jiyan's Echo right before entering the
                              burst combo so the Echo buff is active. Refer to
                              the Echo guide on whether you'll need to Swap-
                              Cancel it.)
                            </li>
                            <li>Intro</li>
                            <li>Forte: Emerald Storm</li>
                            <li>
                              Heavy Attack: Lance of Qinqlong P1 (Interrupt as
                              fast as possible using Resonance Skill)
                            </li>
                            <li>Resonance Skill</li>
                            <li>Heavy Attack: Lance of Qinqlong P1</li>
                            <li>Heavy Attack: Lance of Qinqlong P2</li>
                            <li>Heavy Attack: Lance of Qinqlong P3</li>
                            <li>Heavy Attack: Lance of Qinqlong P1</li>
                            <li>Heavy Attack: Lance of Qinqlong P2</li>
                            <li>Heavy Attack: Lance of Qinqlong P3</li>
                            <li>Resonance Skill</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'danjin' && (
                      <>
                        <blockquote>
                          <h6>BURST COMBO</h6>
                          <p>
                            Danjin’s burst combo doesn’t require it to be
                            executed uninterrupted - allowing her to use Dodges
                            or Swapping to other characters before returning to
                            finish the combo. This is as her preferred Echo
                            (Dreamless) has no buff associated with it and her
                            kit lacks any strict duration to fit damage into.
                            However, if using a team with specific Deepen
                            amplification effects that prohibit switching,
                            consider using that first before performing Swap mid
                            combo.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Skill: Crimson Erosion I</li>
                            <li>Skill: Crimson Erosion II</li>
                            <li>Ultimate</li>
                            <li>Skill: Carmine Gleam</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>Skill: Sanguine Pulse I</li>
                            <li>Skill: Sanguine Pulse II</li>
                            <li>Skill: Sanguine Pulse III</li>
                            <li>Skill: Carmine Gleam</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>Skill: Sanguine Pulse I</li>
                            <li>Skill: Sanguine Pulse II</li>
                            <li>Skill: Sanguine Pulse III</li>
                            <li>Heavy Attack: Chaoscleave Full</li>
                            <li>Heavy Attack: Scatterbloom Full</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'yuanwu' && (
                      <>
                        <p>
                          Unlike most primary DPS and Hybrid dealers, Yuanwu
                          features no set rotation when played and instead
                          focuses on his Ultimate and Resonance Skill, which
                          make up the majority of his endgame capabilities.
                          While we have assigned him a “rotation” for the
                          purpose of calculations, it is better to refer to the
                          following list as things to watch out for during his
                          gameplay instead:
                        </p>
                        <blockquote>
                          <h6>Ability Priority</h6>
                          <ul>
                            <li>Echo </li>
                            <li>Intro (If Available)</li>
                            <li>Skill: Place Thunderwedge</li>
                            <li>Ultimate (Detonates Thunderwedge)</li>
                            <li>Skill: Forte: Rumbling Spark</li>
                            <li>Skill: Place Thunderwedge</li>
                            <li>Outro (If Available)</li>
                            <li></li>
                            <li>
                              Skill: Place Thunderwedge (As needed to keep
                              active and on top of enemies)
                            </li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'encore' && (
                      <>
                        <blockquote>
                          <h6>EASY & BASIC BURST COMBO</h6>
                          <p>
                            When playing teams that focus on Encore's Ultimate
                            or when you have field time to spare after all other
                            cooldowns have been expended in Quick Swap team's
                            execute the following burst combo on Encore.
                          </p>
                          <p>
                            Ending Encore’s Ultimate early to cast Rupture
                            before duration concludes can be a viable strategy
                            in Quick Swap teams, so as to not waste other DPS
                            cooldowns. Whereas, if Encore is the sole DPS that
                            is buffed by teammates, this is generally advised
                            against.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>Skill: Cosmos: Rampage</li>
                            <li>Basic Attack: Cosmos: Frolicking P1</li>
                            <li>Basic Attack: Cosmos: Frolicking P2</li>
                            <li>Basic Attack: Cosmos: Frolicking P3</li>
                            <li>Basic Attack: Cosmos: Frolicking P4</li>
                            <li>Skill: Cosmos: Rampage</li>
                            <li>Basic Attack: Cosmos: Frolicking P1</li>
                            <li>Basic Attack: Cosmos: Frolicking P2</li>
                            <li>Basic Attack: Cosmos: Frolicking P3</li>
                            <li>Basic Attack: Cosmos: Frolicking P4</li>
                            <li>Skill: Cosmos: Rampage</li>
                            <li>
                              Heavy Attack: Cosmos: Rupture (Swap-Cancel The
                              Moment Encore Begin's Channelling)
                            </li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                        <blockquote>
                          <h6>ADVANCED BURST COMBO</h6>
                          <p>
                            For those aiming even more damage when playing
                            Encore and are confident in their mechanical
                            abilities, there are a number of tricks you can
                            employ to increase her damage at cost of difficulty
                            increase in battle. This is achieved via executing
                            more Basic Attacks after each Ultimate, here is what
                            you will need to adjust:
                          </p>
                          <ul>
                            <li>
                              Core of new rotates lies in cancellation of
                              Encore’s 4th enhanced Basic Attack animation,
                              allowing you to start your next action much
                              faster. This can be achieved by interrupting it
                              via Skill usage or a Dash, you will need to
                              perform one of these cancels 3 times during her
                              Ultimate in this new rotation.
                            </li>
                            <li>
                              The other change is, this rotation gives one
                              enhanced Skill use in favor of 4 more Basic
                              Attacks, this leads to increased damage but leaves
                              you with very less time to execute the entire
                              combo without losing out on final Basic Attack,
                              Skill and Forte Heavy Attack if you mess up.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>Basic Attack: Cosmos: Frolicking P1</li>
                            <li>Basic Attack: Cosmos: Frolicking P2</li>
                            <li>Basic Attack: Cosmos: Frolicking P3</li>
                            <li>Basic Attack: Cosmos: Frolicking P4</li>
                            <li>
                              Skill: Cosmos: Rampage (Use Skill to animation
                              cancel Frolicking P4)
                            </li>
                            <li>Basic Attack: Cosmos: Frolicking P1</li>
                            <li>Basic Attack: Cosmos: Frolicking P2</li>
                            <li>Basic Attack: Cosmos: Frolicking P3</li>
                            <li>
                              Basic Attack: Cosmos: Frolicking P4 (Dash Cancel
                              this attack)
                            </li>
                            <li>Basic Attack: Cosmos: Frolicking P1</li>
                            <li>Basic Attack: Cosmos: Frolicking P2</li>
                            <li>Basic Attack: Cosmos: Frolicking P3</li>
                            <li>Basic Attack: Cosmos: Frolicking P4</li>
                            <li>
                              Skill: Cosmos: Rampage (Use Skill to animation
                              cancel Frolicking P4)
                            </li>
                            <li>
                              Heavy Attack: Cosmos: Rupture (Swap-Cancel the
                              moment Encore begins channelling)
                            </li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'calcharo' && (
                      <>
                        <blockquote>
                          <h6>OPTIMIZED BURST COMBO</h6>
                          <p>
                            Below given is an optimized combo that implements
                            either a Skill Cancel or Dash Cancel to allow faster
                            accumulation of Killing Intent by Calcharo. The
                            slowest part of Hounds Roar 5 Attack combo, required
                            to generate 5 Killing Intent to cast Death Messenger
                            is its part 4 and 5, by using Skill or Dash after
                            3rd part, you can reset the combo, gaining last 2
                            charges with part 1 and 2, thus speeding up your
                            access to Death Messengers. While this combo is
                            challenging to execute in spur of the moment, it
                            offers higher chance to execute 3 Death Messengers
                            and minimize time wasted in long attack animations,
                          </p>
                          <p>
                            When playing Calcharo without Quick Swaps, we
                            recommend using Skill Cancel as it generates more
                            Concerto and Resonance Energy, while dealing more
                            damage and being easier to use. When using
                            Swap-Cancels, Calcharo’s skill will be placed on
                            cooldown after one use, forcing use of Dash Cancel
                            to continue the combo. Both approaches are valid and
                            we suggest you try both to find what fits you
                            better, otherwise refer to the easier combo below.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>
                              Heavy Attack: Death Messenger (Optionally
                              Swap-Cancel if you can - for higher damage and to
                              protect Calcharo)
                            </li>
                            <li>Basic Attack: Hounds Roar P1</li>
                            <li>Basic Attack: Hounds Roar P2</li>
                            <li>Basic Attack: Hounds Roar P3</li>
                            <li>
                              Skill: Extermination Order P1 OR Dash Cancel (Use
                              Skill Right after you gain a charge of the forte)
                            </li>
                            <li>Basic Attack: Hounds Roar P1</li>
                            <li>Basic Attack: Hounds Roar P2</li>
                            <li>
                              Heavy Attack: Death Messenger (swap cancel if you
                              can - for higher damage and to protect Calcharo)
                            </li>
                            <li>Basic Attack: Hounds Roar P1</li>
                            <li>Basic Attack: Hounds Roar P2</li>
                            <li>Basic Attack: Hounds Roar P3</li>
                            <li>
                              Skill: Extermination Order P2 OR Dash Cancel (Use
                              Skill right after you gain a charge of the forte)
                            </li>
                            <li>Basic Attack: Hounds Roar P1</li>
                            <li>Basic Attack: Hounds Roar P2</li>
                            <li>
                              Heavy Attack: Death Messenger (Swap-Cancel if you
                              can - for higher damage and to protect Calcharo)
                            </li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                        <Row className="video">
                          <Col xs={12} lg={6}>
                            <YoutubeEmbed embedId="HWzEV-7nMMI" />
                          </Col>
                        </Row>
                      </>
                    )}
                    {slug === 'chixia' && (
                      <>
                        <blockquote>
                          <h6>BURST COMBO</h6>
                          <p>
                            <strong>
                              Chixia features one of the hardest burst combo
                              sequences to successfully complete in endgame
                              content due to multiple reasons such as:
                            </strong>{' '}
                            Using her Echo, especially if buff related Echo such
                            as Inferno Rider or Mech Abomination before
                            initiating her burst combo. With her Echo buff in
                            place, move away from target to build some distance
                            to make landing combo easier and trigger her Intro.
                          </p>
                          <p>
                            Immediately after Intro skill, use her Resonance
                            Skill to consume Forte Gauge built in Warm-Up phase.
                            Repeat this until you reach the indicator on her
                            Forte Gauge then tap Basic Attack to fire BOOM BOOM.
                            A full execution of this ability takes 4 seconds and
                            while it allows movement, failing to complete the
                            full duration will cause massive damage loss.
                          </p>
                          <p>
                            While channeling Chixia’s Forte Gauge, aim for
                            exactly 30 Bullets consumption, as any longer will
                            leave you dry for the next channel. While
                            channeling, she gains a massive attack boost, which
                            is why we use it just before her Ultimate, following
                            which use another full Forte channel into BOOM BOOM
                            and lastly Outro, before repeating the whole
                            sequence.
                          </p>
                          <p>
                            <strong>It's extremely important</strong> to note
                            that while pairing her Forte Skills and Ultimate
                            result in large damage increases, it is more
                            important to land them in first place. As such, you
                            are free to completely ignore the sequence below and
                            instead use each ability as opportunity presents
                            itself, even if it ends up in damage loss.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Skill: Forte: Thermobaric Bullets</li>
                            <li>Skill: Forte: BOOM BOOM</li>
                            <li>Ultimate</li>
                            <li>Skill: Forte: Thermobaric Bullets</li>
                            <li>Skill: Forte: BOOM BOOM</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'rover-spectro' && (
                      <>
                        <blockquote>
                          <h6>COMBO REQUIREMENTS</h6>
                          <p>
                            Thanks to an incredibly accessible Forte Gauge,
                            Ultimate and very short Resonance Skill cooldown,
                            Spectro Rover can directly jump into action without
                            any Warm-Up phase.
                          </p>
                        </blockquote>
                        <blockquote>
                          <h6>BURST COMBO</h6>
                          <p>
                            Spectro Rover requires a Quick Swap mid-rotation to
                            unlock their maximum potential. This Swap is
                            performed to abuse their extremely short Resonance
                            Skill cooldown of only 6 seconds. Use one Skill
                            initially alongside Rover’s Ultimate, perform a
                            Quick Swap and use a Skill or Echo on another
                            character and Swap back in to use another Resonance
                            Skill combo for maximum damage with little downtime.
                            This way Rover is always using their most effective
                            and highest damage action throughout the majority of
                            the fight.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Heavy Attack</li>
                            <li>Heavy Attack: Resonance</li>
                            <li>Heavy Attack: Aftertune</li>
                            <li>Skill: Forte: Resonanting Spin</li>
                            <li>Basic Attack: Forte: Resonating Echoes</li>
                            <li>
                              Ultimate (Quick Swap While Waiting For Skill)
                            </li>
                            <li>Skill: Forte: Resonanting Spin</li>
                            <li>Basic Attack: Forte: Resonating Echoes</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'aalto' && (
                      <>
                        <blockquote>
                          <ul>
                            <li>
                              Skill: Mist Avatar (Use during another character's
                              rotation to allow for cooldown)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>
                              Skill: Mist Avatar (Can be used after Basic
                              Attacks if still on Cooldown)
                            </li>
                            <li>Basic P1</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>Basic P4</li>
                            <li>Basic P5</li>
                            <li>Echo (Impermenance Heron)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'yangyang' && (
                      <blockquote>
                        <ul>
                          <li>Intro</li>
                          <li>Resonance Liberation</li>
                          <li>Resonance Skill</li>
                          <li>Basic Attack P1</li>
                          <li>Basic Attack P2</li>
                          <li>Basic Attack P3</li>
                          <li>Basic Attack P4</li>
                          <li>Mid-Air Attack: Feather Release</li>
                          <li>
                            Heron OR Turtle Echo (Swap-Cancel after first part
                            hits)
                          </li>
                          <li>Outro</li>
                        </ul>
                      </blockquote>
                    )}
                    {slug === 'sanhua' && (
                      <>
                        <blockquote>
                          <h6>HEAVY ATK CONCERTO ROTATION</h6>
                          <ul>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>Skill</li>
                            <li>Heavy Attack: Detonate</li>
                            <li>Heavy Attack: Detonate</li>
                            <li>Echo (Impermanance Heron Swap-Cancel)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                        <blockquote>
                          <h6>BASIC ATK CONCERTO ROTATION</h6>
                          <ul>
                            <li>Intro</li>
                            <li>Basic Attack P3</li>
                            <li>Basic Attack P4</li>
                            <li>Basic Attack P5</li>
                            <li>Ultimate</li>
                            <li>Skill</li>
                            <li>Heavy Attack: Detonate</li>
                            <li>Echo (Impermanance Heron Swap-Cancel)</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'mortefi' && (
                      <>
                        <blockquote>
                          <ul>
                            <li>Intro</li>
                            <li>Skill: Passionate Variation</li>
                            <li>Skill: Fury Fugue</li>
                            <li>Basic P1</li>
                            <li>Basic P2</li>
                            <li>Basic P3</li>
                            <li>Basic P4</li>
                            <li>Skill: Fury Fugue</li>
                            <li>Ultimate</li>
                            <li>Echo (Impermanance Heron Swap-Cancel)</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'lingyang' && (
                      <>
                        <blockquote>
                          <h6>BURST COMBO</h6>
                          <p>
                            Lingyang’s combo is incredibly simple, just make
                            sure you have full energy available on him before
                            Swapping to him and enter with his Intro to generate
                            a portion of his Forte Gauge required. Follow it up
                            with his Ultimate and perform a Heavy Attack to
                            become airborne.
                          </p>
                          <p>
                            While airborne, alternate between using a single
                            Basic Attack and a single Resonance Skill to take
                            advantage of his Inherent Skill: Diligent Practice.
                            Ensure that you do not chain 2 of same type together
                            while alternating between them, as it is very easy
                            to accidentally do the same and lose damage overall.
                          </p>
                          <p>
                            Under ideal circumstances, you can execute 9
                            independant attacks during his Ultimate duration,
                            but if you get Stunned or use Dodge, this number
                            will reduce. While the damage loss from this isn’t a
                            disaster, you may need to spend extra time building
                            energy via other characters to make up for it. While
                            his Ultimate is on cooldown or he lacks the energy,
                            utilize that time on the rest of the team recharging
                            their Concerto Energy and preparing buffs for his
                            next burst combo.
                          </p>
                          <ul>
                            <li>
                              Echo (Use before combo but refer to Echo
                              recommendations for exact specifics for your
                              chosen Echo)
                            </li>
                            <li>Intro</li>
                            <li>Ultimate</li>
                            <li>
                              Heavy Attack: Glorious Plunge (Used to enter Forte
                              and become airborne)
                            </li>
                            <li>Basic Attack: Feral Gyrate P1</li>
                            <li>Skill: Mountain Roamer</li>
                            <li>Basic Attack: Feral Gyrate P2</li>
                            <li>Skill: Mountain Roamer</li>
                            <li>Basic Attack: Feral Gyrate P1</li>
                            <li>Skill: Mountain Roamer</li>
                            <li>Basic Attack: Feral Gyrate P2</li>
                            <li>Skill: Mountain Roamer</li>
                            <li>Basic Attack: Feral Gyrate P1</li>
                            <li>Basic Attack: Stormy Kicks</li>
                            <li>Mid-Air Attack: Tail Strike</li>
                            <li>Outro</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                    {slug === 'yinlin' && (
                      <>
                        <blockquote>
                          <h6>STANDARD ROTATION</h6>
                          <ul>
                            <li>Echo (Swap-Cancel)</li>
                            <li>Intro</li>
                            <li>Skill: Magnetic Roar</li>
                            <li>Skill: Lightning Execution (Swap-Cancel)</li>
                            <li>Basic P2</li>
                            <li>Basic P3 </li>
                            <li>Basic P4 (Swap-Cancel)</li>
                            <li>Ultimate</li>
                            <li>Chameleon Cipher (Swap-Cancel)</li>
                          </ul>
                        </blockquote>
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Character build</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      And here are the Echoes and Weapons used in the
                      calculations.
                    </p>
                    <div className="build-tips damage">
                      <div className={`single-item without-notes ${element}`}>
                        <WWWeaponEntry
                          name={dpsRanking.weapon}
                          level={[Number(dpsRanking.weapon_S)]}
                          mode="icon"
                          onProfile
                        />
                      </div>
                      <div className={`single-item without-notes ${element}`}>
                        {dpsRanking.set_2 != '' ? (
                          <div className="double-set">
                            <WWSet name={dpsRanking.set_1} mode="profile" />
                            <WWSet name={dpsRanking.set_2} mode="profile" />
                          </div>
                        ) : (
                          <>
                            <WWSet name={dpsRanking.set_1} mode="profile" />
                          </>
                        )}
                      </div>
                      <div className={`single-item without-notes ${element}`}>
                        <WWEchoEntry
                          name={dpsRanking.echo_main}
                          level={[5]}
                          mode="icon"
                          onProfile
                        />
                      </div>
                    </div>
                    <div className="main-stats damage">
                      <div className="box">
                        <div className="stats-inside">
                          <strong className="Overlord">4 cost</strong>
                        </div>
                        <div className="list-stats">
                          <WWStat stat={dpsRanking.echo_4} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className="Elite">3 cost</strong>
                        </div>
                        <div className="list-stats">
                          <WWStat stat={dpsRanking.echo_3} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className="Elite">3 cost</strong>
                        </div>
                        <div className="list-stats">
                          <WWStat stat={dpsRanking.echo_3a} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className="Common">1 cost</strong>
                        </div>
                        <div className="list-stats">
                          <WWStat stat={dpsRanking.echo_1} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className="Common">1 cost</strong>
                        </div>
                        <div className="list-stats">
                          <WWStat stat={dpsRanking.echo_1a} />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <div className="box sub-stats damage">
                          <p>
                            <span>Substats:</span> {dpsRanking.substats}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <strong className="red">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        width="18"
                      />
                    </strong>
                    &nbsp;About Main Echoes skills
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Echoes in Wuthering Waves are an essential part of the
                      character rotations, granting a large amount of Resonance
                      Energy and damage but at the cost of high field time for
                      the most powerful ones. But by using some Echoes right
                      before you're about to switch out on the character, you're
                      able to move to your next character while your previous
                      one is still in the echo animation -{' '}
                      <strong>it’s what we call an Echo Swap Cancel</strong>.
                    </p>
                    <p>
                      Echo Swap Cancel is almost always the optimal way to use
                      Echoes, and will result in big reductions in character
                      field time and large increases to DPS, but not all of the
                      most powerful Echoes can be swap cancelled in this way.
                      For example, the <strong>Feilian Beringal</strong> (
                      <strong className="Aero">Aero</strong>),{' '}
                      <strong>Dreamless</strong> (
                      <strong className="Havoc">Havoc</strong>),{' '}
                      <strong>Tempest Mephis</strong> (
                      <strong className="Electro">Electro</strong>),{' '}
                      <strong>Mourning Aix</strong> (
                      <strong className="Spectro">Spectro</strong>), and{' '}
                      <strong>Infernal Rider</strong> (
                      <strong className="Fusion">Fusion</strong>) Echoes can all
                      be Swap Cancelled.
                    </p>
                    <p>
                      The remaining element, so{' '}
                      <strong className="Glacio">Glacio</strong> while having
                      powerful Echoes{' '}
                      <strong>
                        has none powerful enough that can be swap cancelled
                      </strong>
                      , and to make things worse, no Echoes that generate
                      sufficient Energy and damage for their field time
                      investment.
                    </p>
                    <p>
                      The result of this is that you can expect{' '}
                      <strong className="Glacio">Glacio</strong> characters to{' '}
                      <strong>
                        have higher field time and lower DPS in calculations
                      </strong>{' '}
                      than their other elemental counter parts until they
                      receive better Echoes to use.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        </>
      )}
    </>
  );
};
