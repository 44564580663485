import React from 'react';
import {
  faAnglesDown,
  faAnglesRight,
  faEquals
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  name: string;
  element: string;
}

export const WWSkillPrioProfile: React.FC<IProps> = ({ name, element }) => {
  return (
    <>
      {name === 'the-shorekeeper' && (
        <>
          <h6>Damage-focused Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
          </div>
          <h6 className="with-margin">Healing-focused Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
          </div>
        </>
      )}
      {name === 'xiangli-yao' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'zhezhi' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'changli' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>

          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'jinhsi' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {(name === 'calcharo' || name === 'sanhua') && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {(name === 'rover-spectro' || name === 'mortefi') && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'danjin' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'chixia' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'lingyang' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'jiyan' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'yuanwu' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'baizhi' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
        </div>
      )}
      {name === 'yangyang' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attackl</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skil</p>
          </div>
        </div>
      )}
      {name === 'verina' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'encore' && (
        <>
          <h6>Quick Swap Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
          </div>
          <h6 className="with-margin">Hyper Carry Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
          </div>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <h6>Quick Swap Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
          <h6 className="with-margin">Hyper Carry Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
        </>
      )}
      {name === 'jianxin' && (
        <>
          <h6>Support Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
          <h6 className="with-margin">DPS Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
        </>
      )}
      {name === 'aalto' && (
        <div className={`skill-priority ${element}`}>
          <div className="skill">
            <p>Basic Attack</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Forte Circuit</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Res. Liberation</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faEquals} width="18" />
          </div>
          <div className="skill">
            <p>Res. Skill</p>
          </div>
          <div className="order desktop">
            <FontAwesomeIcon icon={faAnglesRight} width="18" />
          </div>
          <div className="order mobile">
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </div>
          <div className="skill">
            <p>Intro Skill</p>
          </div>
        </div>
      )}
      {name === 'taoqi' && (
        <>
          <h6>Support Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
          <h6 className="with-margin">DPS Variant</h6>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Res. Liberation</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faEquals} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
        </>
      )}
      {name === 'yinlin' && (
        <>
          <div className={`skill-priority ${element}`}>
            <div className="skill">
              <p>Forte Circuit</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Liberation </p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Res. Skill</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Basic Attack</p>
            </div>
            <div className="order desktop">
              <FontAwesomeIcon icon={faAnglesRight} width="18" />
            </div>
            <div className="order mobile">
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </div>
            <div className="skill">
              <p>Intro Skill</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
