/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './ww-weapon-box.scss';

interface IProps {
  name: string;
  char?: string;
  supere?: string;
}

export const WWWeaponNotes: React.FC<IProps> = ({ name, char, supere }) => {
  return (
    <>
      {/* Pistols */}
      {name === 'Static Mist' && (
        <div className="information with-padding">
          Best gun in the game by a landslide, offering main stat CRIT RATE,
          granting better Crit ratios (and as a result scaling), great base
          damage, and a good amount of Energy Regen (which saves you at least 1
          sub-stat on gear). Also currently the only weapon in the game that
          offers any form of team-supportive ability granting the next character
          to switch in via intro an ATK boost synergising immensely with the
          hybrid playstyle.
        </div>
      )}
      {name === 'Novaburst' && (
        <div className="information with-padding">
          Usable gun by all Resonators offering good bonuses to ATK but reliant
          on dodging 3 times during your rotation for maximum value with
          continual dodges after that. Should only be used by those willing to
          perform dodges at the start of their rotation and consistently after.
          Calculations assume you perform dodges as soon as possible and
          maintain the buff.
        </div>
      )}
      {name === 'Undying Flame' && (
        <div className="information with-padding">
          One of the top gun options for Resonators that deals primarily
          Resonance Skill DMG with little to no conditions to worry about thanks
          to its effect being tied to Intro skills. Almost all characters make
          use of Intros in their rotation and the bonus lasts for 15 seconds -
          long enough for most Main DPS to finish their burst window making this
          a strong consistent option.
        </div>
      )}
      {name === 'Pistols#26' && (
        <div className="information with-padding">
          Generic gun option usable by all characters but with a significant
          ramp-up time on its bonus. Also requires the player not to be hit to
          extract full value. If you do get hit this weapon will fall behind
          (calculations assume you don't get hit even once).
        </div>
      )}
      {name === 'Thunderbolt' && (
        <div className="information with-padding">
          Alternative gun option to Undying Flame for Resonance Skill focused
          damage characters, assuming they weave in several Basic Attacks in
          their rotation.
        </div>
      )}
      {name === 'Pistols of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Cadenza' && (
        <div className="information with-padding">
          A utility-focused weapon prioritizing lightning-fast Concerto
          rotations over raw output with the ability to transform entire units'
          action priorities under the right circumstances. Not always a good
          choice but when implemented correctly makes all the difference. Also
          offers an immense amount of Energy Regen further accelerating
          rotations and supportive capabilities.
        </div>
      )}
      {name === 'Pistols of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {/* Broadblade */}
      {char === 'jinhsi' ? (
        <>
          {name === 'Ages of Harvest' && (
            <div className="information with-padding">
              Jinhsi's signature and best in slot weapon granting an exceptional
              amount of Crit Rate, Resonance Skill DMG% and DMG% all stats that
              amplify Jinhsi's damage tremendously. This weapon handily beats
              out all of the competition without trouble. One thing to note when
              using this weapon is that due to the sheer amount of DMG% Jinhsi
              has from her kit, echoes and this weapon it means she can
              comparatively gain higher value than usual when stacking ATK %,
              specifically on her 3 cost echoes instead of Spectro %.
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Ages of Harvest' && (
            <div className="information with-padding">-</div>
          )}
        </>
      )}
      {name === 'Verdant Summit' && (
        <div className="information with-padding">
          Jiyan's signature weapon that's not only fantastic for him but also
          usable on all damage-focused Broadblade users. Offers a huge main stat
          of CRIT DMG% granting excellent scaling, 12% permanent generic DMG%
          and up to 48% increased Heavy ATK DMG% after using 1/2 skills and or a
          Resonance Liberation.
        </div>
      )}
      {name === 'Lustrous Razor' && (
        <div className="information with-padding">
          Top Broadblade option only in competition with some 4★ weapons with
          multiple copies invested or limited 5★ weapons. Offers high base ATK,
          ATK%, a good amount of Energy Regen to save at least 1 sub-stat on
          gear to be reallocated to better uses and a stackable Resonance
          Liberation ATK DMG% bonus that ramps up as you use Resonance Skills.
        </div>
      )}
      {char === 'jinhsi' ? (
        <>
          {name === 'Autumntrace' && (
            <div className="information with-padding">
              Jinhsi cannot easily stack Autumntrace's bonus ability granting
              additional attack as she doesn't make use of her standard basic or
              heavy attacks and her enhanced basic attacks are treated instead
              as Resonance Skill damage thus not counting. Autumntrace is still
              usable but just less potent than on usual.
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Autumntrace' && (
            <div className="information with-padding">
              Strongest 4★ Broadsword option, granting main stat CRIT RATE for
              excellent scaling and an incredibly easy to access bonus ability
              which grants a stacking ATK% buff that only requires you to use
              Basic or Heavy attacks during your rotation.
            </div>
          )}
        </>
      )}
      {name === 'Helios Cleaver' && (
        <div className="information with-padding">
          A generic option, granting a stacking ATK% buff after using your
          Resonance Skill that starts at 0 and grants 1 stack every 2 seconds up
          to 4 stacks - reaching a maximum after 8 seconds. Buff resets shortly
          after the stack cap limit is reached after which you'll have to repeat
          the cycle anew. Falls behind other options due to it's buff being
          inconsistent but still a viable option if you don't have better.
        </div>
      )}
      {name === 'Broadblade#41' && (
        <div className="information with-padding">
          Another generic Broadblade option with a mix of utility and offense,
          granting a large amount of Energy Regen as a main stat. Also a
          reasonably strong and easy to maintain bonus ability that offers an
          ATK% buff as long as you remain above 80% HP. Our calculations assume
          you'll stay above 80% for the whole fight, if you can't you can lower
          this weapons performance.
        </div>
      )}
      {name === 'Broadblade of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Discord' && (
        <div className="information with-padding">
          A utility-focused weapon prioritizing lightning-fast Concerto
          rotations over raw output with the ability to transform entire units'
          action priorities under the right circumstances. Not always a good
          choice but when implemented correctly makes all the difference. Also
          offers an immense amount of Energy Regen further accelerating
          rotations and supportive capabilities.
        </div>
      )}
      {name === 'Broadblade of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {/* Swords */}
      {name === 'Blazing Brilliance' && (
        <div className="information with-padding">
          Changli's signature weapon that's not only fantastic for her but also
          usable on all damage-focused Sword users who have a large portion of
          their damage originating from Resonance Skill damage. Offers a huge
          main stat of CRIT DMG% granting excellent scaling, 12% permanent ATK%
          and up to 56% increased Resonance Skill DMG% which should be at or
          close to full power during the bulk of your rotation when opening with
          one or two resonance skills.
        </div>
      )}
      {name === 'Emerald of Genesis' && (
        <div className="information with-padding">
          Outstanding Sword choice, offering significantly higher base damage
          due to its 5★ rarity, as well as a stackable ATK% buff almost every
          character can effortlessly get 1 stack of (but with 2 also being
          easily achievable by most when played correctly). Additionally has a
          good amount of Energy Regen, which on most characters will allow one
          sub-stat of Energy Regen to be allocated elsewhere to increase damage
          even further. Best Sword choice across the board with only S5 4★
          Swords having a chance to compete with it.
        </div>
      )}
      {name === 'Lumingloss' && (
        <div className="information with-padding">
          Sword option for Heavy and Basic ATK users with an easy to trigger
          passive - with the only downside being its 10 second time limit. For
          characters that use their Skill multiple times per rotation, this
          downside is a non-issue but for Main DPS characters who take up a lot
          of field time and don't use their skill often this sword can lose
          value.
        </div>
      )}
      {name === 'Lunar Cutter' && (
        <div className="information with-padding">
          Powerful sword for Resonators spending minimal time on the field - the
          shorter the better. Best for Hybrid characters executing their
          rotation fast and infrequently, but less powerful on Main DPS
          characters that aim to spend extended time on field.
        </div>
      )}
      {name === 'Commando of Conviction' && (
        <div className="information with-padding">
          Exceptional all-around generic Sword gaining its bonus close to
          unconditionally as most Resonators almost always aim to make use of
          their Intro skill and very few characters spend more than 15 seconds
          on field during their duration.
        </div>
      )}
      {name === 'Sword#18' && (
        <div className="information with-padding">
          Sword with decent base stats but a passive that only activates after
          taking sufficient damage after which it offers a sizable increase
          Heavy Attack DMG. Primarily suitable only for berserker characters
          that sacrifice their life to deal damage like Danjin, but can be used
          strategically after multiple copies have been merged (only calculated
          with bonus active on characters that consume their life to deal
          damage).
        </div>
      )}
      {name === 'Sword of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Overture' && (
        <div className="information with-padding">
          A utility-focused weapon prioritizing lightning-fast Concerto
          rotations over raw output with the ability to transform entire units'
          action priorities under the right circumstances. Not always a good
          choice but when implemented correctly makes all the difference. Also
          offers an immense amount of Energy Regen further accelerating
          rotations and supportive capabilities.
        </div>
      )}
      {name === 'Sword of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {name === 'Originite: Type II' && (
        <div className="information with-padding">
          Sword with the unique function of adding healing to a characters kit
          causing their Resonance Liberation to restore a small amount of health
          to themselves with a cooldown of 20 seconds. This sword has bad combat
          stats and the heal would otherwise be considered terrible for most
          content but it allows characters to activate the incredibly powerful
          5P Rejuvenating Glow set which requires healing to buff. This weapon
          should only be equipped when aiming to use the set on a character who
          could not otherwise heal.
        </div>
      )}
      {/* Gauntlets */}
      {name === "Verity's Handle" && (
        <div className="information with-padding">
          Xiangli Yao's signature and an ideal weapon for any Resonance
          Liberation based Gauntlet user. Grants a tremendous 24.3% CRIT RATE
          boost and 12% generic DMG% boost all permanently and on top of that
          also grants an incredibly convenient 48% Resonance Liberation DMG%
          lasting 8 seconds on use of the wearers Liberation. Due to the timing
          of when this buff activates, it is almost always up when you need it
          most and the duration can also be extended up to 3 times for 5 seconds
          each time whenever you use a resonance skill. This extends the total
          duration of this buff to a whopping 23 seconds.
        </div>
      )}
      {name === 'Abyss Surges' && (
        <div className="information with-padding">
          One of the top Gauntlets options in game with the only competitors
          being 4★ Weapons with multiple dupes. Offers high base ATK, ATK%, a
          good amount of Energy Regen to save at least 1 sub-stat on gear to be
          reallocated to better uses and a conditional bonus boosting Resonance
          Skill damage and Basic Attacks that is very easy to satisfy.
        </div>
      )}
      {name === 'Hollow Mirage' && (
        <div className="information with-padding">
          Strong generic option for all Gauntlets users, offering an ATK% and
          DEF% after using Resonance Liberation in the form of 3 charges. Since
          most characters include their Liberation in their rotation in the
          endgame you can expect this bonus to be applied quickly and
          consistently. Unfortunately, whenever you are hit you'll lose 1 charge
          so to use this weapon effectively, you must minimize how many times
          you're hit. Calculations assume you can maintain your rotation without
          getting hit - if you think you will be lower your expectations of this
          weapon and it's position in the ranking.
        </div>
      )}
      {name === 'Gauntlets#21D' && (
        <div className="information with-padding">
          Gauntlets with a generic ATK% boost but an Energy Regen main stat,
          making them globally usable by all Gauntlets users but best for those
          who require the Energy Regen instead of more damage stats.
        </div>
      )}
      {name === 'Stonard' && (
        <div className="information with-padding">
          Specialist Gauntlets for Resonance Liberation scaling users, thanks to
          its passive that grants increased Resonance Liberation DMG% after
          using a Resonance Skill. Still useable by characters who don't focus
          specifically on Liberation damage as a big part of their kit, thanks
          to the reasonably high base stats but more importantly CRIT RATE main
          stat.
        </div>
      )}
      {name === 'Gauntlets of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {name === 'Gauntlets of Voyager' && (
        <div className="information with-padding">
          An easily accessible supportive option with a reasonable amount of
          Energy Regen. It also grants the unique ability to gain a sizable
          chunk of raw Resonance Energy once every 20 seconds. For most
          character's rotations in teams, this bonus will be ready every time
          it's their turn to switch-in, making this weapon a great choice for
          any character who doesn't need damage but needs their Ultimate fast
          and has trouble accessing it otherwise. The flat energy gain is more
          powerful the less energy the character and team they are a part of can
          generate.
        </div>
      )}
      {name === 'Marcato' && (
        <div className="information with-padding">
          A utility-focused weapon prioritizing lightning-fast Concerto
          rotations over raw output with the ability to transform entire units'
          action priorities under the right circumstances. Not always a good
          choice but when implemented correctly makes all the difference. Also
          offers an immense amount of Energy Regen further accelerating
          rotations and supportive capabilities.
        </div>
      )}
      {name === 'Originite: Type IV' && (
        <div className="information with-padding">
          Gauntlets with the unique function of adding healing to a characters
          kit causing their Basic Attacks to restore a small amount of health to
          themselves with a cooldown of 3 seconds. The Gauntlets have bad combat
          stats and the heal would otherwise be considered terrible for most
          content but it allows characters to activate the incredibly powerful
          5P Rejuvenating Glow set which requires healing to buff. This weapon
          should only be equipped when aiming to use the set on a character who
          could not otherwise heal.
        </div>
      )}
      {name === 'Amity Accord' && (
        <div className="information with-padding">
          Outstanding damage focused option for DEF-scaling damage dealers that
          deal a reasonable portion of their damage with their Resonance
          Liberation. Current best option for all DEF scalers.
        </div>
      )}
      {name === 'Guardian Gauntlets' && (
        <div className="information with-padding">
          Free to play accessible alternative for DEF-scaling damage dealers
          that make use of their Resonance Liberation as a damage dealing tool.
          A good alternative if you don't have Amity Accord.
        </div>
      )}
      {/* Rectivier */}
      {name === 'Rime-Draped Sprouts' && (
        <div className="information with-padding">
          Zhezhi's Signature weapon that is tailored for her and characters that
          deal a large amount of their damage through Basic Attacks. Characters
          that intend to use this weapon should also be able to frequently
          launch Resonance Skills - ideally every 6 seconds while fighting. As a
          baseline the weapon offers a tremendous 72% CRIT DMG main stat
          alongside an unconditional 12% ATK buff, all on top of its Basic
          Attack DMG buffing capabilities. When using any Resonance Skill, the
          wearer gains a stack granting 12% Basic Attack DMG for 6 seconds
          stackable up to 3 times. This effect isn't easy to keep active for
          characters that aren't Zhezhi but if you can it's a nice bonus. Upon
          casting an Outro, if the wearer has a full 3 stacks of the buff all of
          them will be consumed replacing the buff with a 52% Basic Attack DMG
          bonus for 27 seconds but only while that character remains off the
          field.
        </div>
      )}
      {name === 'Stellar Symphony' && (
        <div className="information with-padding">
          Strongest option for Shorekeeper, offering an absurd amount of Energy
          Recharge, a strong team wide buff (which should be up close to
          permanently) and the extra concerto she needs to smooth out her
          rotation. Variation can situationally have some use over this weapon
          at high ranks if you need extra concerto to further shorten
          Shorekeeper's rotations but if you're not interested in that and don't
          need the concerto this weapon wins in all other scenarios.
        </div>
      )}
      {name === 'Stringmaster' && (
        <div className="information with-padding">
          Yinlin's signature Rectifier that stands far above all other options
          currently in the game for Rectifier damage dealers, thanks to its
          unethical amount of CRIT RATE and generic damage boost on top of a
          reasonably easy to active ATK% boost.
        </div>
      )}
      {name === 'Cosmic Ripples' && (
        <div className="information with-padding">
          An excellent Rectifier, only in competition with some 4★ Weapons with
          multiple dupes. Offers high base ATK, ATK%, a good amount of Energy
          Regen to save at least 1 sub-stat on gear to be reallocated to better
          uses and a stackable Basic ATK DMG% bonus that ramps up as you use
          more and more Basics.
        </div>
      )}
      {name === 'Augment' && (
        <div className="information with-padding">
          Outstanding generic Rectifier option, offering base CRIT RATE for
          excellent scaling but also an easily activatable and maintainable
          bonus ability granting ATK% after the wearer's Resonance Liberation is
          activated. Almost any character who can use CRIT RATE and ATK will be
          more than happy to have this weapon available only losing to 5★
          options.
        </div>
      )}
      {name === 'Jinzhou Keeper' && (
        <div className="information with-padding">
          A good alternative 4★ Rectifier to Augment, offering an easy to
          maintain bonus ability granting ATK% after using an Intro skill and a
          good amount of ATK% as a main stat.
        </div>
      )}
      {char === 'the-shorekeeper' ? (
        <>
          {name === 'Variation' && (
            <div className="information with-padding">
              Best option for Shorekeeper outside of her signature - even if you
              only have S1 as it still grants enough concerto to run an optimal
              rotation without any additional actions. Also grants a large
              amount of Energy Recharge as well making it an great alternative
              option. Higher ranks of this weapon are beneficial and allow for
              even faster rotations for Shorekeeper if certain concerto
              breakpoints can be hit (e.g. relevant for those who own
              Shorekeeper's Sequence 3).
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Variation' && (
            <div className="information with-padding">
              A utility-focused weapon prioritizing lightning-fast Concerto
              rotations over raw output with the ability to transform entire
              units' action priorities under the right circumstances. Not always
              a good choice but when implemented correctly makes all the
              difference. Also offers an immense amount of Energy Regen further
              accelerating rotations and supportive capabilities.
            </div>
          )}
        </>
      )}
      {name === 'Rectifier of Night' && (
        <div className="information with-padding">
          Easily accessible 3★ weapon that despite having lower base attack
          makes up for it with a high ATK% bonus at higher dupes. It does not
          have enough stats to compete with 4-star weapons but is a reasonable
          option if it's all you have. The only downside is that the buff only
          lasts 10 seconds, meaning it may fall off in the hands of a Main DPS
          character who takes up more field time.
        </div>
      )}
      {char === 'the-shorekeeper' ? (
        <>
          {name === 'Rectifier of Voyager' && (
            <div className="information with-padding">
              A lower rarity weapon option every should have at maximum rank
              compared to Rectifer #25 but with the downside of granting less
              Energy Recharge on top of not having the concerto generation of
              her Signature or Variation.
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Rectifier of Voyager' && (
            <div className="information with-padding">
              An easily accessible supportive option with a reasonable amount of
              Energy Regen. It also grants the unique ability to gain a sizable
              chunk of raw Resonance Energy once every 20 seconds. For most
              character's rotations in teams, this bonus will be ready every
              time it's their turn to switch-in, making this weapon a great
              choice for any character who doesn't need damage but needs their
              Ultimate fast and has trouble accessing it otherwise. The flat
              energy gain is more powerful the less energy the character and
              team they are a part of can generate.
            </div>
          )}
        </>
      )}
      {char === 'the-shorekeeper' ? (
        <>
          {name === 'Rectifier#25' && (
            <div className="information with-padding">
              An easier to acquire alternative to Variation or Stellar Symphony
              that still offers Shorekeeper an excellent amount of Energy
              Recharge for her Resonance Liberation. Does not have any bonus
              concerto generation so additional actions will be required on top
              of the her standard optimal rotation in order to generate enough
              concerto for her Outro.
            </div>
          )}
        </>
      ) : (
        <>
          {name === 'Rectifier#25' && (
            <div className="information with-padding">-</div>
          )}
        </>
      )}
      {name === 'Celestial Spiral' && (
        <div className="information with-padding">-</div>
      )}
      {name === 'Relativistic Jet' && (
        <div className="information with-padding">-</div>
      )}
      {name === 'Endless Collapse' && (
        <div className="information with-padding">-</div>
      )}
      {name === 'Fusion Accretion' && (
        <div className="information with-padding">-</div>
      )}
      {name === 'Waning Redshift' && (
        <div className="information with-padding">-</div>
      )}
    </>
  );
};
